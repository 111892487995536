<template>
    <v-container fluid>
        <v-row class=" mx-1 px-1">
            <v-col cols="12">
                <h2 class="primary--text">Listagem de Materiais</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-divider class="mb-12"></v-divider>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col cols="12" md="2">
                        <div class="d-none d-lg-block text-right">
                            <v-btn
                                color="primary"
                                dark
                                small
                                tile
                                @click="exportMaterials('Excel')"
                                >
                                <v-icon left>
                                    mdi-export
                                </v-icon>
                                Exportar
                                </v-btn>
                        </div>
                    </v-col>
                </v-row>
                <PaginatedTable
                    :headers="headers"
                    :items="items"
                    :loading="loading"
                    :server-items-length="total"
                    @optionsChanged="searchMaterials"
                    class="mt-4"
                    locale="pt-pt"
                    @deleted="deleteRow"
                    :dataSearch="false"
                    :canView="false"
                    :canDelete="true"
                >
                </PaginatedTable> 
            </v-col>
        </v-row>
        <v-dialog
            v-model="chooseDate"
            width="500"
        >
        <DatesPicker @confirm="startExport" @close="chooseDate = false"/>
        </v-dialog>
    <Loader v-if="loader"/>
    </v-container>                        
</template>
<script>
import PaginatedTable from "@/components/UI/Tables/PaginatedTable"
import DatesPicker from "@/components/UI/Modals/DatesPicker.vue";
import Loader from "@/components/UI/Loaders/Loader";

export default {
    components: {
        PaginatedTable,
        DatesPicker,
        Loader
    },
    data: () => ({
        loader: false,
        chooseDate: false,
        loading: true,
        items: [],
        headers: [
            {
                text: "Obra",
                align: "center",
                value: "obra.Descricao",
            },
            {
                text: "Frente Obra",
                align: "center",
                value: "frente_nome",
            },
            {
                text: "Classe",
                align: "center",
                value: "classe_nome"
            },
            {
                text: "Material",
                align: "center",
                value: "descricao"
            },
            {
                text: "Unidade",
                align: "center",
                value: "unidade_base"
            },
            {
                text: "Opções",
                align: "center",
                value: "actions"
            }
        ],
        filter:{},
        options: {},        
        total: 0,
    }),
    mounted(){
        if(!this.$store.getters.hasPermission(["super", "materials.read"])) {
            this.$router.push('/forbidden');
        }
    },
    methods:{
        async startExport(dt1, dt2, extension, secondOption){
            this.loader = true
            let params = {
                init: dt1,
                end: dt2,
                extension: extension,
                secondOption: secondOption,
                type: 'materials'
            }

            // Exportation.export_file(ob)
            await this.$store.dispatch("export_file", params)
            .then((res) => {
                
                this.success = true;
                
                
                
                window.open(res.data.url, '_blank')
                this.loader = false
                
            
                
                this.error = false;

                this.loading = false;

            }).catch(err => {
                this.loader = false

                console.log(err)
            });
        },
        async exportMaterials(){
            this.chooseDate = true
        },
        deleteRow(line){
            
            this.$store.dispatch("deleteMaterials", line.id)
            .then(() => {
                this.success = true;

                this.error = false;

                this.searchMaterials(this.options)

                this.loading = false;

            }).catch(err => {

                console.log(err)
            });
        },
        searchMaterials(options){
            this.options = options;

            this.loading = true;

            let filter = {...this.filter};

            filter.page = options.page;

            filter.limit = options.itemsPerPage;

            this.$store.dispatch("getAll", filter)
                .then(response => {

                    let items = response.data;

                    this.total = items.total;

                    this.items = items.data;

                    this.loading = false;

            });
        }
    },
    computed: {
        crumbs: function() {
        return [
            {
            text: 'Listagem de Materiais',
            disabled: true,
            to: '#',
            exact: true,
            },
        ];
        }
    }
}
</script>
<template>
    <section>
        <v-row class="d-none d-lg-block mx-1 px-1">
            <v-progress-linear v-if="loading"
                indeterminate
                class="global-loader"
            ></v-progress-linear>
            <v-col cols="12">
                <section v-if="!loading">
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    light
                    :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
                    ></v-breadcrumbs>
            
                    <h1 class="primary--text">
                        <v-icon color="primary">mdi-hard-hat</v-icon> <span>Registo Diário Encarregado</span>
                    </h1>

                    <v-btn @click="$router.push('/dashboard')" style="float: right;" class="primary">
                      <v-icon class="mr-2">mdi-view-dashboard</v-icon>
                      Dashboard
                    </v-btn>
                    
                    <v-btn v-if="canApprove.flag" @click="confirmApprove" style="float: left;" class="success">
                      <v-icon class="mr-2">mdi-check-decagram</v-icon>
                      Aprovar diária
                    </v-btn>

                    <div class="mt-16">
                    <v-progress-linear v-if="loading"
                        indeterminate
                        class="global-loader"
                    ></v-progress-linear>
            
                    <template v-if="loading">
                        <v-row>
                        <v-col cols="12" md="6">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        </v-row>
                        <v-row>
                        <v-col cols="12">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        </v-row>
                        <v-row>
                        <v-col cols="12">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        </v-row>
                        <v-row class="mt-6" align="center" justify="space-around">
                        <v-skeleton-loader
                            type="button"
                            ></v-skeleton-loader>
                        </v-row>
                    </template>
                    <Supervisor method="update" :diary="diary"></Supervisor>
                    </div>
                </section>
            </v-col>
        </v-row>
        <v-row class="d-block d-lg-none">
            <v-progress-linear v-if="loading"
                indeterminate
                class="global-loader"
            ></v-progress-linear>
            <v-col cols="12" offset="0" md="12" offset-md="0">
                <section class="mt-16" v-if="!loading">
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    light
                    :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
                    ></v-breadcrumbs>
            
                    <h1 class="primary--text">
                    <v-icon color="primary">mdi-hard-hat</v-icon> <span>Registo Diário Encarregado</span>
                    </h1>
            
                    <div class="mt-16">
                    <v-progress-linear v-if="loading"
                        indeterminate
                        class="global-loader"
                    ></v-progress-linear>
            
                    <template v-if="loading">
                        <v-row>
                        <v-col cols="12" md="6">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        </v-row>
                        <v-row>
                        <v-col cols="12">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        </v-row>
                        <v-row>
                        <v-col cols="12">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        </v-row>
                        <v-row class="mt-6" align="center" justify="space-around">
                        <v-skeleton-loader
                            type="button"
                            ></v-skeleton-loader>
                        </v-row>
                    </template>
                    <Supervisor method="update" :diary="diary"></Supervisor>
                    </div>
                </section>
            </v-col>
        </v-row>
        <DialogConfirmation 
            v-if="confirmationModal.show"
            :title="confirmationModal.title" 
            type="warning" 
            :opened="confirmationModal.show" 
            :elevation="0"
            @on-submit="aprovarDiaria" 
            @on-cancel="confirmationModal.item = null; confirmationModal.show = false;">
                Tem a certeza que deseja aprovar a diária?
        </DialogConfirmation>

    </section>
</template>
<script>
import Supervisor from '@/components/Diaries/Supervisor/Supervisor.vue'
import PDEq from "@/api/PDEq.js";
import PDMO from "@/api/PDMO.js";
import Diaries from "@/api/Diaries.js";
import User from "@/api/User.js";
import Approval from "@/api/Approval.js";
import DialogConfirmation from '@/components/UI/Modals/DialogConfirmation.vue';

export default {
  components: {
    Supervisor,
    DialogConfirmation
  },
  data: () => ({
        canApprove: {
            flag: false,
            object: null
        },
        diary: {},
        loading:true,
        obras_user_tem_acesso: [],       
        confirmationModal:{
            show: false,
            title: '',
            message: '',
            approvalItem: null
        },   
  }),
  mounted(){  
    if(!this.$store.getters.hasRole(["super", "encarregado", "administrativo", "ceo", "engenheiro"])) {
        this.$router.push('/forbidden');
    } 

      let params={
          id_user: this.$route.params.id_user,
          hash: this.$route.params.hash,
          type: 'encarregado'
      }

      Diaries.find(params).then((resp)  =>  {
          this.diary = resp.data
          this.checkIfCanApprove()
          this.loading = false
      })        

      User.getUserObras(this.$store.state.user.data.id).then((resp)    =>  {
            this.obras_user_tem_acesso = resp.data
        })

  },
  methods:{
    checkIfCanApprove(){
        if(this.$cookies.isKey("can_approve_diary_cookie")){
            this.canApprove.flag = this.$cookies.get("can_approve_diary_cookie")
            this.canApprove.object = this.$cookies.get("can_approve_diary_object_cookie")
            this.$cookies.remove("can_approve_diary_cookie");
            this.$cookies.remove("can_approve_diary_object_cookie");
        }else{
            this.canApprove.flag = false
            this.canApprove.object = null
        }
    },
    confirmApprove(){
        // Esta linha é a que impede a aprovação com a integração do JOSÉ ALVES
        /*if(approval.all_lines_with_costs == false){
            return
        }*/

        this.confirmationModal.title = 'Tem a certeza?'
        this.confirmationModal.message = 'Tem a certeza que pretende aprovar a diária?'
        this.confirmationModal.show = true
    },
    async aprovarDiaria(){
            let approval = this.canApprove.object
            
            if(approval != null){
                // Send hash from Header
                await Approval.approve(approval)
                    .then(response => {
                        
                        this.confirmationModal.show = false

                        window.location.reload()

                    }).catch(err => {
                        reject(err);
                        
                    }); 
                
            }else{
                this.error_message = 'Um erro aconteceu ao aprovar a diária! Por favor tente mais tarde'
                this.error = true
                this.confirmationModal.item = null
                this.confirmationModal.show = false
            }
    },
    refresh(){
      
    }
  },
  computed: {
    crumbs: function() {
      return [
          {
              text: 'Registo Diário Encarregado',
              disabled: true,
              to: '#',
              exact: true,
          },
      ];
    }
  }
};
</script>

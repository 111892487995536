<template>
    <v-container fluid>
        <v-progress-linear
            v-if="loading"
            class="mb-8"
            color="primary"
            indeterminate
        ></v-progress-linear>
        <!-- EQUIPAMENTO INTERNO -->
        <v-row v-if="!loading" no-gutters>
            <v-col cols="12">
                <v-card class="elevation-0">
                    <v-card-title class="d-none d-lg-block warning white--text">
                        Equipamento Interno
                        <div>
                            <v-btn v-if="$store.getters.hasPermission(['super', 'diaries.import'])" x-small class="primary" elevation="0" @click="triggerFileInput">
                                Importar Excel
                            </v-btn>
                            <input  v-if="$store.getters.hasPermission(['super', 'diaries.import'])"
                            type="file" 
                            ref="fileInput" 
                            @change="handleFileUpload" 
                            accept=".xls,.xlsx" 
                            style="display: none" 
                            />
                        </div>
                    </v-card-title>
                    <v-card-title class="d-block d-lg-none warning white--text text-caption text-center">
                        Equipamento Interno
                        <div>
                            <v-btn v-if="$store.getters.hasPermission(['super', 'diaries.import'])" x-small class="primary" elevation="0" @click="triggerFileInput">
                                Importar Excel
                            </v-btn>
                            <input  v-if="$store.getters.hasPermission(['super', 'diaries.import'])"
                            type="file" 
                            ref="fileInput" 
                            @change="handleFileUpload" 
                            accept=".xls,.xlsx" 
                            style="display: none" 
                            />
                        </div>
                    </v-card-title>
                    <v-card-text class="ma-0 pa-0">
                        <v-simple-table class="d-none d-lg-block pa-0 ma-0" fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th width="3%" v-if="aggregator">
                                            
                                        </th>
                                        <th class="text-left" width="12%">
                                            Equipamento
                                        </th>
                                        <th class="text-left" width="12%">
                                            Frente Obra
                                        </th>
                                        <th class="text-left" width="12%">
                                            Classe
                                        </th>
                                        <th class="text-left" width="7%">
                                            HS / dia
                                        </th>
                                        <th class="text-left" width="7%">
                                            HO
                                        </th>
                                        <th class="text-left" width="7%">
                                            HA
                                        </th>
                                        <th class="text-left" width="7%">
                                            HP
                                        </th>
                                        <th class="text-left" width="7%">
                                            Horas
                                        </th>
                                        <th class="text-left" width="7%">
                                            KM
                                        </th>
                                        <th class="text-left" width="12%">
                                            Mão Obra
                                        </th>
                                        <th v-if="aggregator" width="6%">
                                            Criador
                                        </th>
                                        <th v-if="$store.getters.hasRole(['super', 'administrativo', 'engenheiro'])" width="6%">
                                            Faturar
                                        </th>
                                        <th class="text-left" width="2%">
                                            Opções
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="item in computeEquipamentosInternos" :key="item.id"
                                        :class="item.id.includes('non-editable') ? 'my-orange' : (verificarCustosLinha(item) == true ? '' : 'my-grey')"
                                    >
                                    
                                        <td v-if="aggregator">
                                            <v-icon v-if="lineTotalApproved(item)" color="success">mdi-check-decagram</v-icon>
                                            <span v-else>{{lineApprovalState(item)}}</span>
                                        </td>
                                        <td>
                                            <validation-provider v-if="toEditRow(item)" v-slot="{ errors }" vid="equipamento" name="equipamento" rules="required">
                                                <v-autocomplete
                                                    class="mt-4"
                                                    outlined dense
                                                    :items="equipamentos.interno"                                                    
                                                    :disabled="item.id.includes('non-editable')"
                                                    :item-text="item => item.EntityKey + ' - ' + item.Descricao"
                                                    item-value="Id"
                                                    label="Equipamento"
                                                    :error-messages="errors"
                                                    v-model="item.equipamento"
                                                    @change="calculateEquipmentCost(item, 'i')"
                                                />
                                            </validation-provider>
                                            <v-text-field
                                                v-else
                                                dense outlined
                                                class="mt-4"
                                                label="Equipamento"
                                                :disabled="true"
                                                :value="item.EquipCode + ' - ' + item.EquipDescricao"
                                            />
                                        </td>
                                        <td>
                                            <div v-if="showFrente(item)">
                                                <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="required">
                                                    <v-autocomplete                                                                                
                                                        v-model="item.frente_id"
                                                        :items="frentes_obra"
                                                        outlined dense
                                                        class="mt-4"
                                                        :disabled="item.id.includes('non-editable')"
                                                        :item-text="ob => ob.Descricao"
                                                        item-value="ID"
                                                        :error-messages="errors"
                                                        label="Frentes de Obra *"
                                                        @change="changeFrenteObraV2(item)"
                                                    />
                                                </validation-provider>
                                            </div>
                                        </td>
                                        <td>
                                            <div v-if="showClasse(item)">
                                                <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                                                    <v-autocomplete
                                                        v-model="item.classe_id"
                                                        :items="item.available_classes"
                                                        class="mt-4"
                                                        outlined dense
                                                        :item-text="ob => ob.Descricao"
                                                        :disabled="item.id.includes('non-editable')"
                                                        item-value="ID"
                                                        :error-messages="errors"
                                                        label="Classe *"
                                                    />
                                                </validation-provider>
                                            </div>
                                        </td>    
                                        <td>
                                            <v-text-field 
                                                class="mt-4"
                                                label="HS"
                                                :disabled="item.id.includes('non-editable')"
                                                outlined dense
                                                placeholder="Insira HS"
                                                v-model="item.hs"
                                            />
                                        </td>     
                                        <td>
                                            <v-text-field 
                                                class="mt-4"
                                                label="HO"
                                                outlined dense
                                                placeholder="Insira HO"
                                                :disabled="item.id.includes('non-editable')"
                                                v-model="item.ho"
                                            />
                                        </td>                               
                                        <td>
                                            <v-text-field 
                                                class="mt-4"
                                                label="HA"
                                                outlined dense
                                                placeholder="Insira HA"
                                                :disabled="item.id.includes('non-editable')"
                                                v-model="item.ha"
                                            />
                                        </td>                           
                                        <td>
                                            <v-text-field 
                                                class="mt-4"
                                                label="HP"
                                                outlined dense
                                                placeholder="Insira HP"
                                                :disabled="item.id.includes('non-editable')"
                                                v-model="item.hp"
                                            />
                                        </td>                     
                                        <td>
                                            <v-text-field 
                                                class="mt-4"
                                                label="Horas"
                                                outlined dense
                                                placeholder="Insira Horas"
                                                :disabled="item.id.includes('non-editable')"
                                                v-model="item.horas"
                                            />
                                        </td>                     
                                        <td>
                                            <v-text-field 
                                                class="mt-4"
                                                label="KM"
                                                outlined dense
                                                placeholder="Insira KM"
                                                :disabled="item.id.includes('non-editable')"
                                                v-model="item.km"
                                            />
                                        </td>
                                        <td>
                                            <v-autocomplete
                                                v-if="!item.id.includes('non-editable')"
                                                :items="getMaoObra"
                                                v-model="item.user_id"
                                                class="mt-4"
                                                dense outlined
                                                :item-text="item => item.funcionario"
                                                :item-value="item => item.funcionario"
                                                :disabled="item.id.includes('non-editable')"
                                                placeholder="Mão Obra"
                                            />
                                            <v-text-field
                                                dense outlined class="mt-4"
                                                :value="item.manobrador"
                                                v-else
                                            />
                                        </td>
                                        <td v-if="aggregator">
                                            <v-text-field
                                                class="mt-4"
                                                dense outlined
                                                label="Criado por:"
                                                :disabled="item.id.includes('non-editable')"
                                                :value="item.creator_code + ' - ' + item.creator_name"
                                            />
                                        </td>
                                        <td v-if="$store.getters.hasRole(['super', 'engenheiro', 'administrativo'])">
                                            <v-autocomplete
                                                label="Faturar"
                                                v-model="item.faturar"
                                                :items="item.faturar_values"
                                                dense outlined
                                                :disabled="item.id.includes('non-editable')"
                                                class="mt-4"
                                            />
                                        </td>
                                        <td v-if="aggregator">
                                            <v-btn
                                                v-if="!aggregator"
                                                dark
                                                small
                                                color="error"
                                                @click="deleteEquipamentoInterno(item.id)"
                                                >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>                                                 
                                            <v-btn
                                                v-if="aggregator"
                                                fab
                                                dark
                                                x-small
                                                outlined
                                                color="warning"
                                                @click="edit(item)"
                                            >
                                                <v-icon dark>
                                                    mdi-pencil
                                                </v-icon>
                                            </v-btn>
                                        </td>
                                        <td v-else>
                                            <v-speed-dial
                                                v-model="fabOptions[item.id]"
                                                direction="left"
                                                style="position: absolute; transform: translateY(-50%)"
                                            >
                                                <template v-slot:activator>                                                                                
                                                    <v-btn
                                                        v-if="fabOptions[item.id]" 
                                                        :class="item.local == null || item.local == '' ? 'primary elevation-0' : 'primary elevation-10'" fab small @click="$emit('nota', item, 'equipamento_interno')">
                                                        <v-icon v-if="item.local == null || item.local == ''">mdi-note-edit</v-icon>
                                                        <v-badge
                                                            v-else
                                                            color="orange"
                                                            dot
                                                            overlap
                                                        >
                                                            <v-icon>mdi-note-plus</v-icon>
                                                        </v-badge>
                                                    </v-btn>
                                                    <v-badge
                                                        :color="(item.local == null || item.local == '') ? 'transparent' : 'primary'"
                                                        dot
                                                        overlap
                                                    >
                                                        <v-btn
                                                            v-if="!fabOptions[item.id]"
                                                            small
                                                            v-model="fabOptions[item.id]"
                                                            color="warning"
                                                            dark
                                                            fab
                                                        >
                                                            <v-icon>
                                                                mdi-dots-horizontal
                                                            </v-icon>
                                                        </v-btn>
                                                    </v-badge>
                                                    <v-btn
                                                        v-if="fabOptions[item.id]"
                                                        fab
                                                        dark
                                                        small
                                                        color="error"
                                                        @click="deleteEquipamentoInterno(item.id)"
                                                    >
                                                        <v-icon>mdi-delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                
                                            </v-speed-dial>
                                        </td>
                                    
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                        <v-data-iterator
                            :items="fields.equipamentosInternos"
                            item-key="id"
                            :items-per-page="-1"
                            hide-default-footer
                            class="d-block d-lg-none"
                        >
                        <template v-slot:default="{ items }">
                            <v-row>
                                <v-col
                                    v-for="item in items"
                                    :key="item.id"
                                    cols="12"
                                >
                                    <v-card elevation="0" class="mt-2 rounded-lg" outlined>
                                        <v-list
                                            dense
                                        >
                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <validation-provider v-if="toEditRow(item)" v-slot="{ errors }" vid="equipamento" name="equipamento" rules="required">
                                                        <v-autocomplete
                                                            class="mt-4"
                                                            outlined dense
                                                            :items="equipamentos.interno"
                                                            :item-text="item => item.EntityKey + ' - ' + item.Descricao"
                                                            item-value="Id"
                                                            label="Equipamento"
                                                            :error-messages="errors"
                                                            v-model="item.equipamento"
                                                            @change="calculateEquipmentCost(item, 'i')"
                                                        />
                                                    </validation-provider>
                                                    <v-text-field
                                                        v-else
                                                        dense outlined
                                                        class="mt-4"
                                                        label="Equipamento"
                                                        :disabled="true"
                                                        :value="item.EquipCode + ' - ' + item.EquipDescricao"
                                                    />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <div v-if="showFrente(item)">
                                                        <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="required">
                                                            <v-autocomplete                                                                                
                                                                v-model="item.frente_id"
                                                                :items="frentes_obra"
                                                                outlined dense
                                                                class="mt-4"
                                                                :item-text="ob => ob.Descricao"
                                                                item-value="ID"
                                                                :error-messages="errors"
                                                                label="Frentes de Obra *"
                                                                @change="changeFrenteObraV2(item)"
                                                            />
                                                        </validation-provider>
                                                    </div>
                                                </v-list-item-content>
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <div v-if="showClasse(item)">
                                                        <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                                                            <v-autocomplete
                                                                v-model="item.classe_id"
                                                                :items="item.available_classes"
                                                                class="mt-4"
                                                                outlined dense
                                                                :item-text="ob => ob.Descricao"
                                                                item-value="ID"
                                                                :error-messages="errors"
                                                                label="Classe *"
                                                            />
                                                        </validation-provider>
                                                    </div>
                                                </v-list-item-content>
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field 
                                                        class="mt-4"
                                                        label="HS"
                                                        outlined dense
                                                        placeholder="Insira HS"
                                                        v-model="item.hs"
                                                    />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field 
                                                        class="mt-4"
                                                        label="HO"
                                                        outlined dense
                                                        placeholder="Insira HO"
                                                        v-model="item.ho"
                                                    />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field 
                                                        class="mt-4"
                                                        label="HA"
                                                        outlined dense
                                                        placeholder="Insira HA"
                                                        v-model="item.ha"
                                                    />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field 
                                                        class="mt-4"
                                                        label="HP"
                                                        outlined dense
                                                        placeholder="Insira HP"
                                                        v-model="item.hp"
                                                    />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field 
                                                        class="mt-4"
                                                        label="Horas"
                                                        outlined dense
                                                        placeholder="Insira Horas"
                                                        v-model="item.horas"
                                                    />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field 
                                                        class="mt-4"
                                                        label="KM"
                                                        outlined dense
                                                        placeholder="Insira km"
                                                        v-model="item.km"
                                                    />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-btn :class="item.local == null || item.local == '' ? 'primary elevation-0' : 'primary elevation-10'" fab small @click="$emit('nota', item, 'equipamento_interno')">
                                                        <v-icon v-if="item.local == null || item.local == ''">mdi-note-edit</v-icon>
                                                        <v-badge
                                                            v-else
                                                            color="orange"
                                                            dot
                                                            overlap
                                                        >
                                                            <v-icon>mdi-note-plus</v-icon>
                                                        </v-badge>
                                                    </v-btn>
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item v-if="aggregator">
                                                <v-list-item-content class="align-center">
                                                    <v-text-field
                                                        class="mt-4"
                                                        dense outlined
                                                        label="Criado por:"
                                                        :value="item.creator_code + ' - ' + item.creator_name"
                                                    />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-btn                                                        
                                                        v-if="!aggregator"
                                                        dark
                                                        small
                                                        color="error"
                                                        @click="deleteEquipamentoInterno(item.id)"
                                                        >
                                                        <v-icon small>mdi-delete</v-icon>
                                                    </v-btn>                                                  
                                                    <v-btn
                                                        v-if="aggregator"
                                                        fab
                                                        dark
                                                        x-small
                                                        outlined
                                                        color="warning"
                                                        @click="edit(item)"
                                                    >
                                                        <v-icon dark>
                                                            mdi-pencil
                                                        </v-icon>
                                                    </v-btn>
                                                </v-list-item-content>                                                                            
                                            </v-list-item>
                                        </v-list>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </template>
                        </v-data-iterator>

                        <v-row justify="center" class="mt-4">
                            <v-col cols="4" class="text-left">
                                <v-btn class="primary" small fab @click="paginacaoInterna.menor == 0 ? paginacaoInterna.menor = 0 : paginacaoInterna.menor-=step;paginacaoInterna.maior-=step">
                                    <v-icon>mdi-chevron-left</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="4" align="center" class="text-center">
                                <v-btn fab @click="addEquipamentoInterno" class="primary">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="4" class="text-right">
                                <v-btn class="primary" small fab @click="paginacaoInterna.menor+=step;paginacaoInterna.maior+=step">
                                <v-icon>mdi-chevron-right</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- EQUIPAMENTO INTERNO -->

        <!-- EQUIPAMENTO EXTERNO -->
        <v-row v-if="!loading">
            <v-col cols="12">
                <v-card class="elevation-0">
                    <v-card-title class="d-none d-lg-block warning white--text">
                        Equipamento Externo
                        <div>
                            <v-btn v-if="$store.getters.hasPermission(['super', 'diaries.import'])" x-small class="primary" elevation="0" @click="triggerFileInputExternal">
                                Importar Excel
                            </v-btn>
                            <input  v-if="$store.getters.hasPermission(['super', 'diaries.import'])"
                            type="file" 
                            ref="fileInputExternal" 
                            @change="handleFileUploadExternal" 
                            accept=".xls,.xlsx" 
                            style="display: none" 
                            />
                        </div>
                    </v-card-title>
                    <v-card-title class="d-block d-lg-none warning white--text text-caption text-center">
                        Equipamento Externo
                        <div>
                            <v-btn v-if="$store.getters.hasPermission(['super', 'diaries.import'])" x-small class="primary" elevation="0" @click="triggerFileInputExternal">
                                Importar Excel
                            </v-btn>
                            <input  v-if="$store.getters.hasPermission(['super', 'diaries.import'])"
                            type="file" 
                            ref="fileInputExternal" 
                            @change="handleFileUploadExternal" 
                            accept=".xls,.xlsx" 
                            style="display: none" 
                            />
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table class="d-none d-lg-block" fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th width="3%" v-if="aggregator">
                                            
                                        </th>
                                        <th class="text-left" width="12%">
                                            Equipamento
                                        </th>
                                        <th class="text-left" width="12%">
                                            Frente Obra
                                        </th>
                                        <th class="text-left" width="12%">
                                            Classe
                                        </th>
                                        <th class="text-left" width="7%">
                                            HS / dia
                                        </th>
                                        <th class="text-left" width="7%">
                                            HO
                                        </th>
                                        <th class="text-left" width="7%">
                                            HA
                                        </th>
                                        <th class="text-left" width="7%">
                                            HP
                                        </th>
                                        <th class="text-left" width="7%">
                                            Horas
                                        </th>
                                        <th class="text-left" width="7%">
                                            KM
                                        </th>
                                        <th class="text-left" width="12%">
                                            Mão Obra
                                        </th>
                                        <th v-if="aggregator" width="6%">
                                            Criador
                                        </th>
                                        <th v-if="$store.getters.hasRole(['super', 'administrativo', 'engenheiro'])" width="6%">
                                            Faturar
                                        </th>
                                        <th class="text-left" width="2%">
                                            Opções
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="item in computeEquipamentosExternos" :key="item.id"
                                        :class="verificarCustosLinha(item) == true ? '' : 'my-grey'"
                                    >
                                        <td v-if="aggregator">
                                            <v-icon v-if="lineTotalApproved(item)" color="success">mdi-check-decagram</v-icon>
                                            <span v-else>{{lineApprovalState(item)}}</span>
                                        </td>
                                        <td>
                                            <validation-provider v-if="toEditRow(item)" v-slot="{ errors }" vid="equipamento_externo" name="equipamento_externo" rules="required">
                                                <v-autocomplete
                                                    class="mt-4"
                                                    outlined dense
                                                    :items="equipamentos.externo"
                                                    :item-text="item => item.EntityKey + ' - ' + item.Descricao"
                                                    item-value="Id"
                                                    label="Equipamento"
                                                    :error-messages="errors"
                                                    v-model="item.equipamento"
                                                    @change="calculateEquipmentCost(item, 'e')"
                                                />
                                            </validation-provider>
                                            <v-text-field
                                                v-else
                                                dense outlined
                                                class="mt-4"
                                                label="Equipamento"
                                                :disabled="true"
                                                :value="item.EquipCode + ' - ' + item.EquipDescricao"
                                            />
                                        </td>
                                        <td>
                                            <div v-if="showFrente(item)">
                                                <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="required">
                                                    <v-autocomplete                                                                                
                                                        v-model="item.frente_id"
                                                        :items="frentes_obra"
                                                        outlined dense
                                                        class="mt-4"
                                                        :item-text="ob => ob.Descricao"
                                                        item-value="ID"
                                                        :error-messages="errors"
                                                        label="Frentes de Obra *"
                                                        @change="changeFrenteObraV2(item)"
                                                    />
                                                </validation-provider>
                                            </div>
                                            <span v-else>{{item.frente_obra == null ? '-' : item.frente_obra}}</span>
                                        </td>
                                        <td>
                                            <div v-if="showClasse(item)">
                                                <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                                                    <v-autocomplete
                                                        v-model="item.classe_id"
                                                        :items="item.available_classes"
                                                        class="mt-4"
                                                        outlined dense
                                                        :item-text="ob => ob.Descricao"
                                                        item-value="ID"
                                                        :error-messages="errors"
                                                        label="Classe *"
                                                    />
                                                </validation-provider>
                                            </div>
                                            <span v-else>{{item.classe_obra == null ? '-' : item.classe_obra}}</span>
                                        </td>
                                        <td>
                                            <v-text-field 
                                                class="mt-4"
                                                label="HS"
                                                outlined dense
                                                placeholder="Insira HS"
                                                v-model="item.hs"
                                            />
                                        </td>
                                        <td>
                                            <v-text-field 
                                                class="mt-4"
                                                label="HO"
                                                outlined dense
                                                placeholder="Insira HO"
                                                v-model="item.ho"
                                            />
                                        </td>
                                        <td>
                                            <v-text-field 
                                                class="mt-4"
                                                label="HA"
                                                outlined dense
                                                placeholder="Insira HA"
                                                v-model="item.ha"
                                            />
                                        </td>
                                        <td>
                                            <v-text-field 
                                                class="mt-4"
                                                label="HP"
                                                outlined dense
                                                placeholder="Insira HP"
                                                v-model="item.hp"
                                            />
                                        </td>
                                        <td>
                                            <v-text-field 
                                                class="mt-4"
                                                label="Horas"
                                                outlined dense
                                                placeholder="Insira Horas"
                                                v-model="item.horas"
                                            />
                                        </td>
                                        <td>
                                            <v-text-field 
                                                class="mt-4"
                                                label="KM"
                                                outlined dense
                                                placeholder="Insira KM"
                                                v-model="item.km"
                                            />
                                        </td>
                                        <td>
                                            <v-autocomplete
                                                :items="getMaoObra"
                                                v-model="item.user_id"
                                                class="mt-4"
                                                dense outlined
                                                :item-text="item => item.funcionario + ' - ' + item.Nome"
                                                :item-value="item => item.funcionario"
                                                placeholder="Mão Obra"
                                            />
                                        </td>
                                        <td v-if="aggregator">
                                            <v-text-field
                                                class="mt-4"
                                                dense outlined
                                                label="Criado por:"
                                                :value="item.creator_code + ' - ' + item.creator_name"
                                            />
                                        </td>
                                        <td v-if="$store.getters.hasRole(['super', 'engenheiro', 'administrativo'])">
                                            <v-autocomplete
                                                label="Faturar"
                                                v-model="item.faturar"
                                                :items="item.faturar_values"
                                                dense outlined
                                                class="mt-4"
                                            />
                                        </td>
                                        <td v-if="aggregator">
                                            <v-btn                                            
                                                v-if="!aggregator"
                                                dark
                                                small
                                                color="error"
                                                @click="deleteEquipamentoExterno(item.id)"
                                                >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>                                                 
                                            <v-btn
                                                v-if="aggregator"
                                                fab
                                                dark
                                                x-small
                                                outlined
                                                color="warning"
                                                @click="edit(item)"
                                            >
                                                <v-icon dark>
                                                    mdi-pencil
                                                </v-icon>
                                            </v-btn>                            
                                        </td>
                                        <td v-else>
                                            <v-speed-dial
                                                v-model="fabOptions[item.id]"
                                                direction="left"
                                                style="position: absolute; transform: translateY(-50%)"
                                            >
                                                <template v-slot:activator>                                                                                
                                                    <v-btn
                                                        v-if="fabOptions[item.id]" 
                                                        :class="item.local == null || item.local == '' ? 'primary elevation-0' : 'primary elevation-10'" fab small @click="$emit('nota', item, 'equipamento_externo')">
                                                        <v-icon v-if="item.local == null || item.local == ''">mdi-note-edit</v-icon>
                                                        <v-badge
                                                            v-else
                                                            color="orange"
                                                            dot
                                                            overlap
                                                        >
                                                            <v-icon>mdi-note-plus</v-icon>
                                                        </v-badge>
                                                    </v-btn>
                                                    <v-badge
                                                        :color="(item.local == null || item.local == '') ? 'transparent' : 'primary'"
                                                        dot
                                                        overlap
                                                    >
                                                        <v-btn
                                                            v-if="!fabOptions[item.id]"
                                                            small
                                                            v-model="fabOptions[item.id]"
                                                            color="warning"
                                                            dark
                                                            fab
                                                        >
                                                            <v-icon>
                                                                mdi-dots-horizontal
                                                            </v-icon>
                                                        </v-btn>
                                                    </v-badge>
                                                    <v-btn
                                                        v-if="fabOptions[item.id]"
                                                        fab
                                                        dark
                                                        small
                                                        color="error"
                                                        @click="deleteEquipamentoExterno(item.id)"
                                                    >
                                                        <v-icon>mdi-delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                
                                            </v-speed-dial>
                                        </td>
                                    
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                        <v-data-iterator
                            :items="fields.equipamentosExternos"
                            item-key="id"
                            :items-per-page="-1"
                            hide-default-footer
                            class="d-block d-lg-none"
                        >
                        <template v-slot:default="{ items }">
                            <v-row>
                                <v-col
                                    v-for="item in items"
                                    :key="item.id"
                                    cols="12"
                                >
                                    <v-card elevation="0" class="mt-2 rounded-lg" outlined>
                                        <v-list
                                            dense
                                        >
                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <validation-provider v-if="toEditRow(item)" v-slot="{ errors }" vid="equipamento" name="equipamento" rules="required">
                                                        <v-autocomplete
                                                            class="mt-4"
                                                            outlined dense
                                                            :items="equipamentos.externo"
                                                            :item-text="item => item.EntityKey + ' - ' + item.Descricao"
                                                            item-value="Id"
                                                            label="Equipamento"
                                                            :error-messages="errors"
                                                            v-model="item.equipamento"
                                                            @change="calculateEquipmentCost(item, 'e')"
                                                        />
                                                    </validation-provider>
                                                    <v-text-field
                                                        v-else
                                                        dense outlined
                                                        class="mt-4"
                                                        label="Equipamento"
                                                        :disabled="true"
                                                        :value="item.EquipCode + ' - ' + item.EquipDescricao"
                                                    />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <div v-if="showFrente(item)">
                                                        <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="required">
                                                            <v-autocomplete                                                                                
                                                                v-model="item.frente_id"
                                                                :items="frentes_obra"
                                                                outlined dense
                                                                class="mt-4"
                                                                :item-text="ob => ob.Descricao"
                                                                item-value="ID"
                                                                :error-messages="errors"
                                                                label="Frentes de Obra *"
                                                                @change="changeFrenteObraV2(item)"
                                                            />
                                                        </validation-provider>
                                                    </div>
                                                    <span v-else>{{item.frente_obra == null ? '-' : item.frente_obra}}</span>
                                                </v-list-item-content>                                                                            
                                            </v-list-item>


                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <div v-if="showClasse(item)">
                                                        <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                                                            <v-autocomplete
                                                                v-model="item.classe_id"
                                                                :items="item.available_classes"
                                                                class="mt-4"
                                                                outlined dense
                                                                :item-text="ob => ob.Descricao"
                                                                item-value="ID"
                                                                :error-messages="errors"
                                                                label="Classe *"
                                                            />
                                                        </validation-provider>
                                                    </div>
                                                    <span v-else>{{item.classe_obra == null ? '-' : item.classe_obra}}</span>
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field 
                                                        class="mt-4"
                                                        label="HS"
                                                        outlined dense
                                                        placeholder="Insira HS"
                                                        v-model="item.hs"
                                                    />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field 
                                                        class="mt-4"
                                                        label="HO"
                                                        outlined dense
                                                        placeholder="Insira HO"
                                                        v-model="item.ho"
                                                    />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field 
                                                        class="mt-4"
                                                        label="HA"
                                                        outlined dense
                                                        placeholder="Insira HA"
                                                        v-model="item.ha"
                                                    />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field 
                                                        class="mt-4"
                                                        label="HP"
                                                        outlined dense
                                                        placeholder="Insira HP"
                                                        v-model="item.hp"
                                                    />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field 
                                                        class="mt-4"
                                                        label="Horas"
                                                        outlined dense
                                                        placeholder="Insira Horas"
                                                        v-model="item.horas"
                                                    />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field 
                                                        class="mt-4"
                                                        label="KM"
                                                        outlined dense
                                                        placeholder="Insira KM"
                                                        v-model="item.km"
                                                    />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-btn :class="item.local == null || item.local == '' ? 'primary elevation-0' : 'primary elevation-10'" fab small @click="$emit('nota', item, 'equipamento_externo')">
                                                        <v-icon v-if="item.local == null || item.local == ''">mdi-note-edit</v-icon>
                                                        <v-badge
                                                            v-else
                                                            color="orange"
                                                            dot
                                                            overlap
                                                        >
                                                            <v-icon>mdi-note-plus</v-icon>
                                                        </v-badge>
                                                    </v-btn>
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item v-if="aggregator">
                                                <v-list-item-content class="align-center">
                                                    <v-text-field
                                                        class="mt-4"
                                                        dense outlined
                                                        label="Criado por:"
                                                        :value="item.creator_code + ' - ' + item.creator_name"
                                                    />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>
                                            
                                            <v-list-item v-if="$store.getters.hasRole(['super', 'administrativo', 'engenheiro'])">
                                                <v-list-item-content class="align-center">
                                                    <v-autocomplete
                                                        class="mt-4"
                                                        dense outlined
                                                        label="Faturar:"
                                                        :value="item.faturar_values"
                                                        v-model="item.faturar"
                                                    />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-btn
                                                        v-if="!aggregator"
                                                        dark
                                                        small
                                                        color="error"
                                                        @click="deleteEquipamentoExterno(item.id)"
                                                        >
                                                        <v-icon small>mdi-delete</v-icon>
                                                    </v-btn>                                                      
                                                    <v-btn
                                                    v-if="aggregator"
                                                        fab
                                                        dark
                                                        x-small
                                                        outlined
                                                        color="warning"
                                                        @click="edit(item)"
                                                    >
                                                        <v-icon dark>
                                                            mdi-pencil
                                                        </v-icon>
                                                    </v-btn>
                                                </v-list-item-content>                                                                            
                                            </v-list-item>
                                        </v-list>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </template>
                        </v-data-iterator>
                        

                        <v-row justify="center" class="mt-4">
                            <v-col cols="4" class="text-left">
                                <v-btn class="primary" small fab @click="paginacaoExterna.menor == 0 ? paginacaoExterna.menor = 0 : paginacaoExterna.menor-=step;paginacaoInterna.maior-=step">
                                    <v-icon>mdi-chevron-left</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="4" align="center" class="text-center">
                                <v-btn fab @click="addEquipamentoExterno" class="primary">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="4" class="text-right">
                                <v-btn class="primary" small fab @click="paginacaoExterna.menor+=step;paginacaoExterna.maior+=step">
                                <v-icon>mdi-chevron-right</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <ErrorSnackbar color="gold" :timeout="6000" v-model="error.show" :message="error.msg"/>

        <!-- EQUIPAMENTO EXTERNO -->
    </v-container>
</template>
<script>
import Classe from "@/api/Classe.js"
import Obra from "@/api/Obra.js"
import dayjs from 'dayjs'
import Diaries from '@/api/Diaries.js'
import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";
import { readExcelFile } from "@/plugins/excelReader";

export default{
    name:"Equipments",
    components:{
        ErrorSnackbar
    },
    props:{
        diarias_manobrador:{
            typeof: Array,
            default: []
        },
        newLines: {
            typeof: Boolean,
            default: true
        },
        aggregator: {
            typeof: Boolean,
            default: false
        },
        obra_selecionada:{
            typeof: Object,
            default: null
        },
        fields: Object,
        frentes_obra: Array,
        loadedClasses: Array,
        hideFrentes: Boolean
    },
    data(){
        return{
            fabOptions:{},
            classes:[],
            equipamentos: null,
            loading: true,
            step: 10,
            paginacaoInterna:{
                menor: 0,
                maior: 10
            },
            paginacaoExterna:{
                menor: 0,
                maior: 10
            },
            error:{
                show: false,
                msg: ''
            }
        }
    },
    mounted(){
        this.classes = this.loadedClasses
        this.fillBaseData()

        this.fields.equipamentosInternos.forEach(item => {
            // Inicializa fabOptions para cada item
            this.$set(this.fabOptions, item.id, false); // false ou true dependendo do seu uso
        });

        this.fields.equipamentosExternos.forEach(item => {
            // Inicializa fabOptions para cada item
            this.$set(this.fabOptions, item.id, false); // false ou true dependendo do seu uso
        });
    },
    methods:{         
        triggerFileInputExternal() {
            this.$refs.fileInputExternal.click();
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        async handleFileUploadExternal(event) {
            const file = event.target.files[0];

            this.$emit('loader')

            try {
                const data = await readExcelFile(file);
                console.log("Dados lidos do Excel");
                this.processExcelDataExternal(data);
            } catch (error) {
                alert(error.message);
            }
        },
        async handleFileUpload(event) {
            const file = event.target.files[0];

            this.$emit('loader')

            try {
                const data = await readExcelFile(file);
                console.log("Dados lidos do Excel");
                this.processExcelData(data);
            } catch (error) {
                alert(error.message);
            }
        },
        processExcelData(data) {
            let result = this.$excelValidator(data, 'eq')
            if(result.success == false){
                alert(result.error);
                return
            }

            

            data = data.slice(1, -2)
            
            let available_classes = []
            let classeNeeded = false;
            if(this.hideFrentes){
                available_classes = this.classes;
                classeNeeded = true;
            }

            let novosItens = []
                
            data.forEach((element, index) => {
                const frente = this.frentes_obra.find(f => f.Descricao === element[1]);
                const frente_id = frente ? frente.ID : null;
                const available_classes_local = frente?.classes ?? available_classes;
                const classeNeeded_local = (frente?.classes?.length || 0) > 0 || classeNeeded;

                const var_classe_code = element[2]?.split('-')[0]?.trim();
                const var_classe = var_classe_code ? available_classes_local.find(c => c.Classe === var_classe_code) : null;

                const equipamento = this.equipamentos.interno.find(eq => eq.Descricao === element[3]);
                const equipamento_value = equipamento ? equipamento.Id : null;

                novosItens.push({
                    id: `${dayjs().unix()}-${index}-new`,
                    codigo: null,
                    frente_id,
                    classe_id: var_classe?.ID ?? null,
                    classeNeeded: classeNeeded_local,
                    available_classes: available_classes_local,
                    equipamento: equipamento_value,
                    hs: +parseFloat(element[4] ?? 0).toFixed(2),
                    ho: +parseFloat(element[5] ?? 0).toFixed(2),
                    ha: +parseFloat(element[6] ?? 0).toFixed(2),
                    hp: 0,
                    horas: 0,
                    km: 0,
                    faturar: 'N',
                    faturar_values: ['S', 'N', 'C'],
                    valor_servico: 0,
                    valor_ordem: 0,
                    local: null,
                    user_id: null,
                    options: ""
                });
            });

            this.fields.equipamentosInternos.push(...novosItens);
            this.$emit('loader')

        },
        processExcelDataExternal(data){
            let result = this.$excelValidator(data, 'eq')
            if(result.success == false){
                alert(result.error);
                return
            }

            

            data = data.slice(1, -2)
            
            let available_classes = []
            let classeNeeded = false;
            if(this.hideFrentes){
                available_classes = this.classes;
                classeNeeded = true;
            }

            let novosItens = []
                
            data.forEach((element, index) => {
                const frente = this.frentes_obra.find(f => f.Descricao === element[1]);
                const frente_id = frente ? frente.ID : null;
                const available_classes_local = frente?.classes ?? available_classes;
                const classeNeeded_local = (frente?.classes?.length || 0) > 0 || classeNeeded;

                const var_classe_code = element[2]?.split('-')[0]?.trim();
                const var_classe = var_classe_code ? available_classes_local.find(c => c.Classe === var_classe_code) : null;

                const equipamento = this.equipamentos.externo.find(eq => eq.Descricao === element[3]);
                const equipamento_value = equipamento ? equipamento.Id : null;

                novosItens.push({
                    id: `${dayjs().unix()}-${index}-new`,
                    codigo: null,
                    frente_id,
                    classe_id: var_classe?.ID ?? null,
                    classeNeeded: classeNeeded_local,
                    available_classes: available_classes_local,
                    equipamento: equipamento_value,
                    hs: +parseFloat(element[4] ?? 0).toFixed(2),
                    ho: +parseFloat(element[5] ?? 0).toFixed(2),
                    ha: +parseFloat(element[6] ?? 0).toFixed(2),
                    hp: 0,
                    horas: 0,
                    km: 0,
                    faturar: 'N',
                    faturar_values: ['S', 'N', 'C'],
                    valor_servico: 0,
                    valor_ordem: 0,
                    local: null,
                    user_id: null,
                    options: ""
                });
            });


            this.fields.equipamentosExternos.push(...novosItens);
            this.$emit('loader')
        },
        verificarCustosLinha(item){
            if(!this.$store.getters.hasRole(['super', 'administrativo', 'engenheiro']))
                return true;

            if(item.valor_servico == null && item.valor_ordem == null){
                return false
            }

            return true
        },
        calculateEquipmentCost(item, place){
            let data = {
                data: this.fields.Data,
                eq_code: item.equipamento,
                obra_id: this.fields.obra_id
            }

            Diaries.getPrecoEquipamento(data).then((resp)   =>  {
                if(resp.data.Result == false){
                    item.valor_servico = null
                    item.valor_ordem = null
                    item.faturar = 'N'
                }else{                    
                    item.valor_servico = resp.data.PrecoNormal
                    item.valor_ordem = resp.data.PrecoExtra
                    item.faturar = resp.data.Faturar
                }
                item.faturar_values = ['S', 'N', 'C']
            })
        },
        lineApprovalState(item){            
            if(this.obra_selecionada == null)
                return null;

            let obra_nivel = this.obra_selecionada.approval_level == null ? 2 : this.obra_selecionada.approval_level
            return (item.approval == null ? 0 : item.approval) + '/' + obra_nivel
        },
        lineTotalApproved(item){  
            if(this.obra_selecionada == null)
                return false;

            if(item.approval == null)
                return false
                console.log(this.obra_selecionada)
                
            if(parseInt(this.obra_selecionada.obra.approval_level) == parseInt(item.approval))
                return true

            return false
        },
        edit(item){
            //this.$router.push("/diarios/encarregado/editar/" + item.webAppLinkHash + "/" + item.id_user);
            window.open(process.env.VUE_APP_API_URL + '/diarios/encarregado/editar/' + item.webAppLinkHash + "/" + item.id_user, '_blank')
        },
        showClasse(item){
            if(item.classeNeeded)
                return true

            return false
        },
        showFrente(item){
            if(this.fields.obra_id != null && this.hideFrentes == false)
                return true

            return false
        },
        classesPorObra(){
            // Também temos de verificar se esta obra tem classes
            Classe.getClassesByObraPai(this.fields.obra_id).then((resp) =>  {
                this.classes = resp.data
               
            })
        },
        async changeFrenteObraV2(item){
            this.loading = true


            let frente = null
            this.frentes_obra.forEach(element => {
                if(element.ID == item.frente_id)
                    frente = element
            });


            let entrei = false
            if(frente != null){
                if(frente.classes != null){
                    if(frente.classes.length > 0){
                        entrei = true
                        item.available_classes = frente.classes
                        item.classeNeeded = true
                    }
                }

                if(entrei == false){
                    let data = {
                        SubEmp: frente.SubEmp
                    }


                    await Classe.getClasses(data).then((resp) =>  {
                        item.available_classes = resp.data

                        if(item.available_classes.length == 0){
                            item.classeNeeded = false
                        }else item.classeNeeded = true
                        
                    })
                }
                
            }



            this.loading = false
        },
        toEditRow(item){
            if(item.id.includes('new'))
                return true

            return false
        },
        fillBaseData(){
            let data = {
                work_id: this.fields.obra_id,
                codigo: this.$store.state.user.data.funcionario.Codigo,
                data: this.fields.Data,
                place: 'encarregado'
            }

            Obra.getObraEquipamentos(data).then((resp)  =>  {
                this.equipamentos = resp.data
                this.loading = false
            })


            if(this.hideFrentes){
                this.classesPorObra()
            }
        },
        addEquipamentoInterno(){
            let available_classes = []
            let classeNeeded = false;
            if(this.hideFrentes){
                available_classes = this.classes;
                classeNeeded = true;
            }

            let tamanho = 0
            if(this.fields.equipamentosInternos != null)
                tamanho = this.fields.equipamentosInternos.length

            this.fields.equipamentosInternos.push({
                id: dayjs().unix() + '-' + tamanho + '-new',
                codigo: null,
                frente_id: null,
                classe_id: null,
                classeNeeded: classeNeeded,
                available_classes: available_classes,
                equipamento: null,
                hs: 0,
                ho: 0,
                ha: 0, 
                hp: 0,
                horas: 0,
                km: 0,
                faturar: null,
                faturar_values: [],
                valor_servico: 0, 
                valor_ordem: 0,
                local: null,
                user_id: null,
                options: ""
            });
        },
        addEquipamentoExterno(){
            let available_classes = []
            let classeNeeded = false;
            if(this.hideFrentes){
                available_classes = this.classes;
                classeNeeded = true;
            }

            let tamanho = 0
            if(this.fields.equipamentosExternos != null)
                tamanho = this.fields.equipamentosExternos.length

            this.fields.equipamentosExternos.push({
                id: dayjs().unix() + '-' + tamanho + '-new',
                codigo: null,
                frente_id: null,
                classe_id: null,
                classeNeeded: classeNeeded,
                available_classes: available_classes,
                equipamento: null,
                hs: 0,
                ho: 0,
                ha: 0,
                hp: 0,
                horas: 0,
                km: 0,
                faturar: null,
                faturar_values: [],
                valor_servico: 0,
                valor_ordem: 0,
                local: null,
                user_id: null,
                options: ""
            });
        },
        deleteEquipamentoInterno(id){
            let pos = -1    
            let i = 0
            this.fields.equipamentosInternos.forEach(element => {
                if(element.id == id){
                    this.fields.deleteEQ.push(id)
                    pos = i
                }

                i++
            });

            this.fields.equipamentosInternos.splice(pos, 1)
        },
        deleteEquipamentoExterno(id){
            let pos = -1    
            let i = 0
            this.fields.equipamentosExternos.forEach(element => {
                if(element.id == id){
                    this.fields.deleteEQ.push(id)
                    pos = i
                }

                i++
            });

            this.fields.equipamentosExternos.splice(pos, 1)
        }
    },
    computed:{
        getMaoObra(){
            return this.fields.funcionariosExternos.concat(this.fields.funcionariosInternos)
        },
        computeEquipamentosInternos(){
            let returner = []

            // Vou mostrar dados de diárias de manobrador. Aqui vou inserir os equipamentos quem vêm dessa diária
            this.diarias_manobrador.forEach(diaria => {
                diaria.parte_diaria.linhas_eq.forEach(element => {
                    returner.push({
                        id: dayjs().unix() + '-' + element.id_user + '-new-non-editable',
                        codigo: element.IdEquipamento,
                        frente_id: diaria.frente_id,
                        classe_id: diaria.classe_id,
                        classeNeeded: diaria.classe_id == null ? false : true,
                        available_classes: this.classes,
                        equipamento: element.IdEquipamento,
                        manobrador: diaria.creator + ' - ' + diaria.creator_name,
                        hs: element.Servico == null ? 0 : parseFloat(element.Servico).toFixed(2),
                        ho: element.Ordem == null ? 0 : parseFloat(element.Ordem).toFixed(2),
                        ha: element.Avariado == null ? 0 : parseFloat(element.Avariado).toFixed(2), 
                        hp: element.Parque == null ? 0 : parseFloat(element.Parque).toFixed(2),
                        horas: element.HNormal == null ? 0 : parseFloat(element.HNormal).toFixed(2),
                        km: element.Contador1 == null ? 0 : parseFloat(element.Contador1),
                        faturar: null,
                        faturar_values: ['S', 'N', 'C'],
                        valor_servico: 0, 
                        valor_ordem: 0,
                        local: null,
                        user_id: null,
                        options: ""
                    })                    
                });
            });

            this.fields.equipamentosInternos.forEach(element => {
                if(element.classe_id != null){
                    if(this.hideFrentes == false){
                        this.frentes_obra.forEach(f => {
                            if(element.frente_id == f.ID){
                                element.available_classes = f.classes
                            }
                        });
                    }else{
                        element.available_classes = this.loadedClasses
                    }
                    element.classeNeeded = true
                }
                returner.push(element)
            });

            return returner.slice(this.paginacaoInterna.menor, this.paginacaoInterna.maior)
        },
        computeEquipamentosExternos(){
            let returner = []

            this.fields.equipamentosExternos.forEach(element => {
                if(element.classe_id != null){
                    if(this.hideFrentes == false){
                        this.frentes_obra.forEach(f => {
                            if(element.frente_id == f.ID){
                                element.available_classes = f.classes
                            }
                        });
                    }else{
                        element.available_classes = this.loadedClasses
                    }
                    element.classeNeeded = true
                }
                returner.push(element)
            });

            return returner.slice(this.paginacaoExterna.menor, this.paginacaoExterna.maior)
        }
    }
}
</script>
<style>

.my-grey{
    background-color: #ededed;
}
</style>
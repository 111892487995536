<template>
    <v-container fluid>
        <v-data-table
            :headers="headers"
            :items="faltas"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            locale="pt-pt"
        >
            <template v-slot:item.actions="{item}">                
                <IconRemove
                    v-if="$store.getters.hasPermission(['super', 'faults.delete'])"
                    class="mr-2"
                    @on-submit="deleteFault(item)"
                  />
            </template>
        </v-data-table>
    </v-container>
</template>
<script>
import Colaboradores from '@/api/Colaboradores.js'
import Faults from '@/api/Faults.js'
import IconRemove from '@/components/UI/IconRemove.vue';

export default{
    name: "Faltas",
    components:{
        IconRemove
    },
    props:{
        day: String,
        work_id: String,
    },
    data(){
        return{            
            loading: true,
            options: {},
            faltas:[],
            total: 0,
            tableFooter: {
            'items-per-page-options': [5, 10, 15, 20],
            'items-per-page-text': "Linhas por página"
            },
            headers: [
            { text: 'Nome', value: 'nome' },
            { text: 'Justificação', value: 'type' },
            { text: 'Ação', value: 'actions' },
            ]
        }
    },
    mounted(){
        this.fillBaseData();
    },
    methods:{
        fillBaseData(){
            let dt =  this.day
            let data = {
                data: dt,
                work_id: this.work_id
            }
            Colaboradores.getFaultsInDay(data).then((resp)  =>  {                
                this.faltas = resp.data.data
                this.total = resp.total
            })
            this.loading = false
        },
        deleteFault(item){
            console.log(item)
            Faults.removeFault(item).then(() =>  {
                this.fillBaseData()
            })
        }
    }
}
</script>
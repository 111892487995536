<template>
    <section class="mt-16">
      <v-row class="mx-1 px-1">
            <v-col cols="12">
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    light
                    ></v-breadcrumbs>
  
                    <h1 class="primary--text">
                    <v-icon color="primary">mdi-hard-hat</v-icon> <span>Registo de Faturas</span>
                    </h1>
  
                    <div class="mt-16">
                      <Fatura method="create"></Fatura>
                </div>
            </v-col>
        </v-row>
    </section>
  </template>
  <script>
  import Fatura from '@/components/Faturas/Fatura'
  
  export default {
    components: {
        Fatura
    },
    data: () => ({
      
    }),
    mounted(){
  
      if(!this.$store.getters.hasPermission(["super", "invoices.create"])) {
        this.$router.push('/admin');
      }
  
    },
    methods:{
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Registo de Faturas',
            disabled: true,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  
import API from "./API";

export default class PDEnc extends API
{
    static async checkExistence(fields){
        return this.post("api/admin/pdenc/checkExistence", fields);
    }
    static async create(fields) {
        return this.post("api/admin/pdenc", fields);
    }

    static async repeatCreateDiary(params){
        return this.post('api/admin/pdenc/repeat', params)
    }

    static async getPDEnc(data) {
        return this.get("api/admin/pdenc/" + data.id + "/" + data.hash, {params: data});
    }
    static async getPDEncHalfLoader(data) {
        return this.get("api/admin/pdenc/" + data.id + "/" + data.hash + '/half-loader', {params: data});
    }
    static async getPDEquipmentERP(data) {
        return this.get("api/admin/pdenc/erp/equipment/" + data.id);
    }
    static async getPDLaborERP(data) {
        return this.get("api/admin/pdenc/erp/labor/" + data.id);
    }
    static async update(fields, id) {
        return this.put("api/admin/pdenc/" + id, fields);
    }
}
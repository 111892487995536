import API from "./API";

export default class DiaryKPI extends API
{
    static async create(fields) {
        return this.post("api/admin/kpi-diaries", fields);
    }

    static async search(filter) {
        return this.get("api/admin/kpi-diaries",{params: filter});
    }

    static async find(id) {
        return this.get("api/admin/kpi-diaries/" + id);
    }

    static async preview(id) {
        return this.get("api/admin/kpi-diaries/preview/" + id);
    }

    static async update(fields, id) {
        return this.put("api/admin/kpi-diaries/" + id, fields);
    }  

    static async checkIfExists(params){
        return this.get("api/admin/kpi-diaries/check-if-exists", {params: params})
    }

    static async aggregator(params){
        return this.get("api/admin/kpi-diaries/aggregator/" + params.id, {params: params})        
    }

}


<template>
  <v-dialog
    :value="opened"
    persistent
    :max-width="$attrs['max-width'] || 600"
    v-bind="$attrs"
    v-on="$listeners"
    @keydown.esc="$emit('on-close')"
  >
    <slot name="activator"></slot>
    <template #default>
      <v-card>
        <v-card-title class="success white--text">
          <h2 class="subtitle-1">
            <v-icon dark class="mr-2">mdi-check-circle</v-icon>{{title}}
          </h2>
        </v-card-title>
        <v-card-text class="content overflow-auto">
          <slot name="content"></slot>
        </v-card-text>
        <v-divider v-if="$slots['footer']" />
        <v-card-actions>
          <v-spacer></v-spacer>
          <slot name="footer"></slot>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: 'uiDialog',
  props: {
    title: {
      type: String,
      default: '',
    },
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: 800;
}
.content {
  min-height: 10.5vh;
  max-height: 68vh;
}
.v-dialog > .v-card > .v-card__text {
  padding: 2rem;
}
</style>

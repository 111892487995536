function excelValidator(data, type = 'mo') {
    let cabecalho = ['Num', 'Frente Obra', 'Unidade Obra', 'Funcionario', 'Qt HN', 'Pr. HN', 'Qt HE', 'Pr. HE', 'Outros', 'Valor'];
    if(type == 'eq')
        cabecalho = ['Codigo', 'Frente Obra', 'Unidade Obra', 'Equipamento', 'Serviço', 'Ordem', 'Qt.Avar', 'Valor']

    let cabecalho_excel = data[0];

    const validarCabecalho = (cab, cab_excel) => {
        return cab.length === cab_excel.length && cab.every((item, index) => item === cab_excel[index]);
    };

    if (!validarCabecalho(cabecalho, cabecalho_excel)) {
        return {
            success: false,
            error: 'O cabeçalho do Excel não é válido!'
        };
    }

    // Encontrar o índice da linha "Total Periodo" (na coluna A)
    const totalPeriodoIndex = data.findIndex((row) => row[0] && String(row[0]).toLowerCase().includes("total periodo"));

    // Se não encontrou, assume até ao fim
    const validRows = data.slice(1, totalPeriodoIndex !== -1 ? totalPeriodoIndex : data.length);

    // Validar cada linha
    for (let i = 0; i < validRows.length; i++) {
        const row = validRows[i];
        const linhaExcel = i + 2; // Para indicar a linha "real" do Excel (começa em 2)

        // Validar se A, E, F, G, H são números
        const camposNumericos = [4, 5, 6, 7];
        for (let col of camposNumericos) {
            if (isNaN(Number(row[col]))) {
                return {
                    success: false,
                    error: `Valor inválido na linha ${linhaExcel}, coluna ${cabecalho[col]}: esperado número.`
                };
            }
        }

        if(type == 'mo'){
            // Validar coluna D (3): não vazia e contém " - "
            const funcionario = row[3];
            if (!funcionario || !funcionario.includes(' - ')) {
                return {
                    success: false,
                    error: `Formato inválido na coluna 'Funcionario' (linha ${linhaExcel}). Esperado: "CÓDIGO - NOME".`
                };
            }
        }
    }

    return {
        success: true,
        error: null
    };
}

export default excelValidator;

<template>
    <v-dialog v-model="showDialog" persistent max-width="600px" overlay-color="#000" overlay-opacity="0.7">
        <v-card>
            <v-card-title class="text-h6 primary white--text font-weight-bold">
                Informação sobre a atualização
            </v-card-title>

            <v-card-text class="mt-4">
                Esta diária tem agendada uma atualização que irá correr em breve no nosso sistema. Pedimos por favor que aguarde um pouco e volte a atualizar a página para poder aceder à mesma.
                <br /><br />
                Caso verifique que o tempo de atualização esteja demasiado demorado, pedimos que entre em contacto com a equipa de suporte.<br />
                Obrigado.
            </v-card-text>

            <v-card-actions class="justify-end">
                <v-btn class="primary" variant="outlined" @click="goToMenu">
                    <v-icon class="mr-2">mdi-view-dashboard</v-icon>Dashboard
                </v-btn>
                <v-btn color="warning" @click="refreshPage">
                <v-icon class="mr-2">mdi-refresh</v-icon>Refresh
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default{
    name: "DialogUpdatingDiary", 
    data(){
        return{
            showDialog: true
        }
    },
    methods:{
        goToMenu() {
            this.$router.push('/diarios/v2')
        },
        refreshPage() {
            window.location.reload()
        }
    }
  }
  
  </script>
  
import API from "./API";

export default class User extends API
{
    static async login(email, password)
    {
        return this.get('sanctum/csrf-cookie')
            .then(() => {
                let data = {
                    email: email,
                    password: password,
                    api: null
                }

                return this.post("/login", data);
            });
    }

    // Microsoft login
    static async microsoftLogin(username, oid) {
        return this.get('sanctum/csrf-cookie')
            .then(() => {
                let data = {
                    email: username,
                    password: oid,
                    api: 'microsoft'
                }

                return this.post("/login", data);
            });

    }

    static async removeMicrosoft(id){

        let data = {
            id: id
        }
        
        return this.post("api/admin/users/remove-microsoft", data);
    }

    static async loginMFA(code) {
        let data = {
            code: code,
        }

        return this.post("/two-factor-challenge", data);
    }

    static async enable2FA() {
        return this.post("/user/two-factor-authentication");
    }

    static async disable2FA() {
        return this.delete("/user/two-factor-authentication");
    }

    static async get2FA() {
        return this.get("/user/two-factor-qr-code");
    }

    static async updateProfileGeneral(name, email, alerts)
    {
        let data = {
            name: name,
            email: email,
            alerts: alerts
        }

        return this.patch("/api/admin/profile/general", data);
    }

    static async updateSystemConfig(params){
        return this.post("api/admin/profile/system-config", params);
    }

    static async updateProfileSecurity(currentPassword, password, passwordConfirmation, mfa) 
    {    
        let data = {
            current_password: currentPassword,
            password: password,
            password_confirmation: passwordConfirmation,
            mfa: mfa
        }

        return this.patch("api/admin/profile/security", data);
    }

    static async updateObras(fields, id) 
    {    
        return this.patch("api/admin/users/" + id + "/obras", fields);
    }

    static async search(filter) {
        return this.get("api/admin/users",{params: filter});
    }

    static async create(fields) {
        return this.post("api/admin/users", fields);
    }

    static async updateUser(fields, id) {
        return this.put("api/admin/users/" + id, fields);
    }

    static async deleteUser(id) {
        return this.delete("api/admin/users/" + id);
    }

    static async getUser(id) {
        return this.get("api/admin/users/" + id);
    }

    static async getUserRole(id) {
        return this.get("api/admin/users/" + id +"/role");
    }

    static async getSession()
    {
        return this.get("api/admin/users/session");
    }

    static async all() {
        return this.get("api/admin/users/list");
    }

    static async logout(id)
    {
        return this.post("/logout", {user: id});
    }

    static async reset(email) {
        return this.post("api/admin/users/reset", {email: email});
    }

    static async updatePassword(params) {
        return this.put("api/admin/users/password", params);
    }

    static async getUserObras(id){
        return this.get("api/admin/users/get-obras/" + id)
    }

    static async verify(params) {
        return this.post("api/admin/users/verify-email", params);
    }

    static async verifyMicrosoft(params) {
        return this.post("api/admin/users/verify-email-microsoft", params);
    }

    static async registerExternalLogin(username, oid, platform, id) {

        let data = {
            username: username,
            password: oid,
            api: platform
        }

        return this.post('api/admin/users/register/' + platform + '/' + id, data)
    }


}
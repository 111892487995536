import API from "./API";

export default class Faults extends API
{
    static async getTypes() {
        return this.get("api/admin/faults/get-types");
    }
    static async createFault(params) {
        return this.post("api/admin/faults", params);
    }

    static async removeFault(params){
        return this.delete("api/admin/faults/" + params.id)
    }

}
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"primary--text"},[_vm._v("Consulta de diárias")]),_c('v-breadcrumbs',{staticClass:"pl-0",attrs:{"large":"","items":_vm.crumbs,"divider":"/"}})],1)],1),_c('div',{staticStyle:{"position":"relative"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-row',{staticClass:"caption mx-4 my-4"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.obras,"outlined":"","dense":"","item-text":function (item) { return item.Codigo + ' - ' + item.Descricao; },"item-value":"Id","label":"Obra a procurar"},model:{value:(_vm.filter.work_id),callback:function ($$v) {_vm.$set(_vm.filter, "work_id", $$v)},expression:"filter.work_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{staticClass:"success",attrs:{"disabled":_vm.filter.work_id == null,"small":"","fab":""},on:{"click":_vm.searchButton}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)],1)],1)],1)],1),(_vm.showContent)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-speed-dial',{attrs:{"absolute":"","top":"","right":"","direction":"bottom","open-on-hover":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","x-large":"","fab":""},model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},'v-btn',attrs,false),on),[(_vm.speedDial)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,false,2362518167)},[_c('span',[_vm._v("Opções")])])]},proxy:true}],null,false,2214108669),model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},[_c('v-tooltip',{attrs:{"right":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"secondary","large":""},on:{"click":function($event){_vm.searching = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}],null,false,3469002047)},[_c('span',[_vm._v("Pesquisar")])])],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"expanded":_vm.expanded,"show-expand":"","single-expand":"","headers":_vm.headers,"loading":_vm.loading,"server-items-length":_vm.total,"items":_vm.items,"footer-props":_vm.tableFooter,"options":_vm.options,"group-by":"data"},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.creator",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-none d-lg-block"},[_c('Tooltip',{staticClass:"my-4 mr-4",attrs:{"color":"primary","text":item.creator_name,"top":""}},[_vm._v(" "+_vm._s(item.creator)+" ")])],1),_c('div',{staticClass:"d-md-none"},[_vm._v(_vm._s(item.creator)+" - "+_vm._s(item.creator_name))])]}},{key:"group.header",fn:function(ref){
var group = ref.group;
var items = ref.items;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{staticClass:"font-weight-bold",attrs:{"cols":"12","md":"2"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{ref:group,attrs:{"small":"","icon":"","data-open":isOpen},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_vm._v(" "+_vm._s(group)+" "),_c('v-btn',{staticClass:"success",attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.editDay(group)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)],1)],1)],1),_c('v-col',{staticClass:"font-weight-bold",attrs:{"cols":"12","md":"10","align":"end"}},[_vm._v(" ("+_vm._s(items.length)+") ")])],1)],1)]}},{key:"item.approval_status",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[(item.creation_role == 'manobrador')?_c('v-icon',{attrs:{"small":"","color":"warning"}},[_vm._v("mdi-steering")]):_vm._e(),(item.creation_role == 'encarregado')?_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v("mdi-account-hard-hat")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[(_vm.checkApprovalStatus(item) == 'total')?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-check-decagram")]):_c('span',[_vm._v(_vm._s(_vm.checkApprovalStatus(item)))])],1),(item.parque_approved)?_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-parking")])],1):_vm._e(),(false)?_c('v-col',{attrs:{"cols":"12","md":"2"}},[(item.sincronizada)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-sync")]):_c('v-icon',[_vm._v("mdi-sync-off")])],1):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.locked == false)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","outlined":"","color":"warning"},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pencil ")])],1)],1),(_vm.checkIfCanApprove(item) == true || _vm.checkIfCanApprove(item) == null)?_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","outlined":"","color":_vm.checkIfCanApprove(item) == null ? 'grey' : 'success'},on:{"click":function($event){return _vm.confirmApprove(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-check-decagram ")])],1),_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-warning ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-btn',{attrs:{"fab":"","x-small":"","outlined":"","color":"blue"},on:{"click":function($event){return _vm.openPreviewDiary(item.id)}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1),(_vm.canDelete(item))?_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","outlined":"","color":"error"},on:{"click":function($event){return _vm.confirmDeleteDiary(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete ")])],1)],1):_vm._e()],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12","align":"start"}},[_c('v-icon',[_vm._v("mdi-lock")])],1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('PreviewDiary',{attrs:{"diary_id":item.id}})],1)]}}],null,false,1326931298)})],1)],1):_vm._e()],1),_c('v-navigation-drawer',{staticClass:"pt-6",attrs:{"fixed":"","left":"","hide-overlay":"","width":"520"},model:{value:(_vm.searching),callback:function ($$v) {_vm.searching=$$v},expression:"searching"}},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"fab":"","small":"","color":"secondary"},on:{"click":function($event){_vm.searching = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"absolute":"","right":"","small":"","color":"error"},on:{"click":_vm.resetFields}},[_vm._v(" Limpar campos ")])],1)],1),_c('v-list',{staticClass:"pt-10"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchDiaries.apply(null, arguments)}}},[_c('v-list-item',[_c('v-autocomplete',{attrs:{"items":[
                            {text: 'Todas', value: 0},
                            {text: 'Por aprovar', value: 1},
                            {text: 'Aprovadas administrativo', value: 2},
                            {text: 'Totalmente aprovadas', value: 3} ],"item-text":"text","item-value":"value","label":"Estado diárias","dense":"","outlined":""},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}})],1),_c('v-list-item',[_c('v-autocomplete',{attrs:{"items":['manobrador', 'encarregado'],"label":"Role","dense":"","outlined":""},model:{value:(_vm.filter.role),callback:function ($$v) {_vm.$set(_vm.filter, "role", $$v)},expression:"filter.role"}})],1),_c('v-list-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data inicial","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.filter.start_date),callback:function ($$v) {_vm.$set(_vm.filter, "start_date", $$v)},expression:"filter.start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"allowed-dates":function (date) { return date <= new Date(new Date()+1).toISOString().substr(0, 10); }},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.filter.start_date),callback:function ($$v) {_vm.$set(_vm.filter, "start_date", $$v)},expression:"filter.start_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data final","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.filter.end_date),callback:function ($$v) {_vm.$set(_vm.filter, "end_date", $$v)},expression:"filter.end_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"allowed-dates":function (date) { return date <= new Date(new Date()+1).toISOString().substr(0, 10); }},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.filter.end_date),callback:function ($$v) {_vm.$set(_vm.filter, "end_date", $$v)},expression:"filter.end_date"}})],1)],1)],1)],1),_c('v-row',{staticClass:"mt-10",attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v(" Procurar ")])],1)],1)],1)],1),(_vm.confirmationModal.show)?_c('DialogConfirmation',{attrs:{"title":_vm.confirmationModal.title,"type":"warning","opened":_vm.confirmationModal.show,"elevation":0},on:{"on-submit":_vm.approve,"on-cancel":function($event){_vm.confirmationModal.item = null; _vm.confirmationModal.show = false;}}},[_vm._v(" "+_vm._s(_vm.confirmationModal.message)+" ")]):_vm._e(),(_vm.confirmationModalDelete.show)?_c('DialogConfirmation',{attrs:{"title":_vm.confirmationModalDelete.title,"type":"warning","opened":_vm.confirmationModalDelete.show,"elevation":0},on:{"on-submit":_vm.deleteDiary,"on-cancel":function($event){_vm.confirmationModalDelete.item = null; _vm.confirmationModalDelete.show = false;}}},[_vm._v(" "+_vm._s(_vm.confirmationModalDelete.message)+" ")]):_vm._e(),_c('v-dialog',{attrs:{"width":"80%","height":"80%"},model:{value:(_vm.previewDiary.show),callback:function ($$v) {_vm.$set(_vm.previewDiary, "show", $$v)},expression:"previewDiary.show"}},[_c('v-card',[(_vm.previewDiary.show)?_c('PreviewDiary',{attrs:{"diary_id":_vm.previewDiary.id}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
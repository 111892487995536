<template>
    <v-navigation-drawer
        app
        permanent
        color="primary"
        dark
        :mini-variant="isMini"
        class="custom-scrollbar"
    > 
        <NodeList :userData="user_data" :items="items" :isMini="isMini"/>
        
        <v-list
            nav
            dense
            v-if="$store.getters.hasPermission(['super', 'tickets.create'])"
        >
            <v-list-item @click="$emit('ticket')">
                <v-list-item-icon>
                    <v-icon :small="isMini">mdi-ticket</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    Ticket
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import NodeList from "@/components/UI/Lists/NodeList"

export default {
    name: "Nav",
    components: {
        NodeList
    },
    props: {
        isMini: Boolean,
        user_data: {
            typeof: Object, default: null
        }
    },
    data(){
        return{
            createTicket: false
        }
    },
    computed: {
        items: function() {
            return [ 
                {
                    
                    path: "/dashboard",
                    name: "Dashboard",
                    icon: "mdi-view-dashboard",
                    items: [],
                    newTab: false,
                    exact: true
                        
                },
                {
                    
                    path: "/preventivas",
                    name: "Máquinas",
                    icon: "mdi-bulldozer",
                    canShow: this.$store.getters.hasPermission(['super']),
                    items: [],
                    newTab: false,
                    exact: true
                        
                },
                {
                    path: "/diarios",
                    name: "Diárias",
                    icon: "mdi-notebook",
                    canShow: this.$store.getters.hasPermission(['super', 'diaries.read']),
                    items: [
                        {
                            path: "/diarios/v2",
                            name: "WebApp Diárias",
                            icon: "mdi-magnify",
                            canShow: this.$store.getters.hasPermission(['super', 'diaries.read']),
                            items: [],
                            newTab: false,
                            exact: true
                        },
                        {
                            path: "/diarios/dia",
                            name: "Consulta diárias",
                            icon: "mdi-magnify",
                            canShow: this.$store.getters.hasPermission(['super', 'diaries.read']),
                            items: [],
                            newTab: false,
                            exact: true
                        },
                        {
                            path: "/diarios",
                            name: "ERP Diárias",
                            icon: "mdi-magnify",
                            canShow: false, //this.$store.getters.hasPermission(['super', 'erp_diaries.read']),
                            items: [],
                            newTab: false,
                            exact: true
                        },
                        {
                            path: "/diarios/manobrador/registar",
                            name: "Registar Diário Manobrador",
                            canShow: this.$store.getters.hasRole(['super', 'manobrador', 'administrativo']),
                            icon: "mdi-plus-circle",
                            items: [],
                            newTab: true,
                            exact: true
                        },
                        {
                            path: "/diarios/encarregado/registar",
                            name: "Registar Diário Encarregado",
                            canShow: this.$store.getters.hasRole(['super', 'encarregado', 'administrativo']),
                            icon: "mdi-plus-circle",
                            items: [],
                            newTab: true,
                            exact: true
                        },
                    ],
                    exact: true
                },  
                {
                    path: "/kpi",
                    name: "KPIs",
                    icon: "mdi-bulldozer",
                    exact: true,
                    canShow: this.$store.getters.hasPermission(['super', 'kpi_diaries.read']),
                    items: [
                        {
                            path: "/kpi/registar",
                            name: "Registar Diária KPI",
                            canShow: this.$store.getters.hasPermission(['super', 'kpi_diaries.create']),
                            icon: "mdi-plus-circle",
                            items: [],
                            newTab: false,
                            exact: true
                        },
                        {
                            path: "/kpi/lista-de-diarios",
                            name: "Diárias KPI",
                            icon: "mdi-magnify",
                            canShow: this.$store.getters.hasPermission(['super', 'kpi_diaries.read']),
                            items: [],
                            newTab: false,
                            exact: true
                        },
                        {
                            path: "/kpi/lista-de-diarios-dia",
                            name: "Consultar KPI/dia",
                            icon: "mdi-magnify",
                            canShow: this.$store.getters.hasPermission(['super', 'kpi_diaries.read']),
                            items: [],
                            newTab: false,
                            exact: true
                        },
                        {
                            path: "/kpi/work_work_units",
                            name: "Obras vs UO",
                            icon: "mdi-format-list-checks",
                            canShow: this.$store.getters.hasPermission(['super', 'kpi_diaries.create', 'kpi_diaries.update']),
                            items: [],
                            newTab: false,
                            exact: true
                        },
                        {                    
                            path: "/kpi/articulados",
                            name: "Consulta de Articulados",
                            icon: "mdi-hard-hat",
                            items: [],
                            exact: true,
                            newTab: false,
                            canShow: this.$store.getters.hasPermission(['super', 'articulados.read']),                   
                        }, 
                        {
                            path: "/kpi/list",
                            name: "Lista de articulados",
                            icon: "mdi-magnify",
                            canShow: false,//this.$store.getters.hasPermission(['super', 'kpi_diaries.read']),
                            items: [],
                            newTab: false,
                            exact: true
                        },
                        {
                            path: "/kpi/activities",
                            name: "Listagem de atividades",
                            icon: "mdi-format-list-checks",
                            canShow: false,//this.$store.getters.hasPermission(['super', 'atividades.read']),
                            items: [],
                            newTab: false,
                            exact: true
                        },
                        {
                            path: "/kpi/categories",
                            name: "Listagem de categorias",
                            icon: "mdi-apps-box",
                            canShow: false, //this.$store.getters.hasPermission(['super', 'categories.read']),
                            items: [],
                            newTab: false,
                            exact: true
                        },                        
                    ]
                },
                {                    
                    path: "/works_consult",
                    name: "Consulta de Obras",
                    icon: "mdi-hard-hat",
                    items: [],
                    exact: true,
                    newTab: false,
                    canShow: this.$store.getters.hasPermission(['super', 'work_consult.read', 'work_ccost.read']),                   
                }, 
                {
                    path: '/configuracoes',
                    name: 'Config. de Obra',
                    icon: 'mdi-cog',
                    canShow: this.$store.getters.hasPermission(['super', 'templates.read', 'templates.create']),
                    items:[
                            {
                                path: "/configuracoes/equipas",
                                name: "Equipas",
                                icon: "mdi-magnify",
                                canShow: false,
                                items: [],
                                newTab: false,
                                exact: true
                            },
                            {
                                path: "/configuracoes/templates",
                                name: "Templates",
                                icon: "mdi-magnify",
                                canShow: this.$store.getters.hasPermission(['super', 'templates.read']),
                                items: [],
                                newTab: false,
                                exact: true
                            },
                            {
                                path: "/configuracoes/templates/registar",
                                name: "Registar Template",
                                icon: "mdi-plus-circle",
                                canShow: this.$store.getters.hasPermission(['super', 'templates.create']),
                                items: [],
                                newTab: false,
                                exact: true
                            },
                            {
                                path: "/configuracoes/unities",
                                name: "Gerir Unidades",
                                icon: "mdi-truck",
                                items: [],
                                exact: true,
                                newTab: false,
                                canShow: this.$store.getters.hasPermission(['super', 'unities.read']),
                            },
                            {
                                path: "/configuracoes/materiais",
                                name: "Lista materiais",
                                icon: "mdi-magnify",
                                items: [],
                                exact: true,       
                                newTab: false,                     
                                canShow: this.$store.getters.hasPermission(['super', 'materials.read']),
                            },
                            {
                                path: "/configuracoes/criar/materiais",
                                name: "Registar Material",
                                icon: "mdi-plus-circle",
                                items: [],
                                exact: true,        
                                newTab: false,                    
                                canShow: this.$store.getters.hasPermission(['super', 'materials.create']),
                            },
                            {
                                path: "/configuracoes/subempreitadas",
                                name: "Gestão subempreitadas",
                                icon: "mdi-magnify",
                                items: [],
                                exact: true,     
                                newTab: false,                       
                                canShow: this.$store.getters.hasPermission(['super', 'subcontract.read']),
                            },
                    ], 
                    exact: true
                },  
                {
                    path: "/garantias",
                    name: "Garantias",
                    icon: "mdi-file-document",
                    canShow: this.$store.getters.hasRole(['super', 'gestor_garantias_obra']),
                    items: [
                        {
                            path: "/garantias/obras",
                            name: "Gestão de garantias",
                            icon: "mdi-hard-hat",
                            items: [],
                            newTab: false,
                            exact: true
                        },
                        {
                            path: "/garantias/clients",
                            name: "Gestão de Clientes",
                            icon: "mdi-account",
                            items: [],
                            newTab: false,
                            exact: true
                        }
                    ]
                },
                {
                    path: "/frota",
                    name: "Frota",
                    icon: "mdi-bulldozer",
                    canShow: this.$store.getters.hasPermission(['super', 'external_communications.read', 'works_schedule.read', 'works_schedule.create', 'odc_machines.read', 'odc_machines.create']),
                    items:[
                        {
                            path: "/frota/external_communications",
                            name: "Comunicações Externas",
                            icon: "mdi-paperclip",
                            exact: true,
                            canShow: this.$store.getters.hasPermission(['super', 'external_communications.read']),
                            items: []
                        },
                        {
                            path: "/frota/work_schedule",
                            name: "Horário de Obras",
                            icon: "mdi-clock",
                            exact: true,
                            canShow: this.$store.getters.hasPermission(['super', 'works_schedule.read', 'works_schedule.create']),
                            items: [
                                {
                                    path: "/frota/works_schedule",
                                    name: "Consultar obras",
                                    icon: "mdi-magnify",
                                    canShow: this.$store.getters.hasPermission(['super', 'odc_machines.read']),
                                    items: [],
                                    exact: true
                                },
                                {
                                    path: "/frota/works_schedule/registar",
                                    name: "Registar horário de obras",
                                    icon: "mdi-plus-circle",
                                    canShow: this.$store.getters.hasPermission(['super', 'odc_machines.create']),
                                    items: [],
                                    exact: true
                                }
                            ]
                        },                     
                        {
                            path: "/frota/odc_machines",
                            name: "Máquinas",
                            icon: "mdi-bulldozer",
                            exact: true,
                            canShow: this.$store.getters.hasPermission(['super', 'odc_machines.read', 'odc_machines.create']),
                            items: [
                                {
                                    path: "/frota/odc_machines",
                                    name: "Consultar máquinas",
                                    icon: "mdi-magnify",
                                    canShow: this.$store.getters.hasPermission(['super', 'odc_machines.read']),
                                    items: [],
                                    exact: true
                                },
                                {
                                    path: "/frota/odc_machines/registar",
                                    name: "Registar máquina",
                                    icon: "mdi-plus-circle",
                                    canShow: this.$store.getters.hasPermission(['super', 'odc_machines.create']),
                                    items: [],
                                    exact: true
                                }
                            ]
                        },
                        {
                            path: '/frota/worker_card',
                            name: 'Associaçao de cartão de Manobrador',
                            icon: "mdi-account",
                            exact: true,
                            canShow: this.$store.getters.hasPermission(['super', 'worker_card.read', 'worker_card.create']),
                            items: [
                                {
                                    path: "/frota/worker_card",
                                    name: "Consultar cartões de manobrador",
                                    icon: "mdi-magnify",
                                    canShow: this.$store.getters.hasPermission(['super', 'worker_card.read']),
                                    items: [],
                                    exact: true
                                },
                                {
                                    path: "/frota/worker_card/registar",
                                    name: "Registar cartões de manobrador",
                                    icon: "mdi-plus-circle",
                                    canShow: this.$store.getters.hasPermission(['super', 'worker_card.create']),
                                    items: [],
                                    exact: true
                                }
                            ],
                        }
                    ]
                },
                {
                    path: "/super",
                    name: "Definições",
                    icon: "mdi-shield-crown",
                    canShow: this.$store.getters.hasPermission(['super', 'users.read', 'users.create', 'roles.read', 'roles.create']),
                    items: [
                        {
                            path: "/super/utilizadores",
                            name: "Utilizadores",
                            icon: "mdi-account",
                            exact: false,
                            canShow: this.$store.getters.hasPermission(['super', 'users.read', 'users.create']),
                            items: [
                                {
                                    path: "/super/utilizadores",
                                    name: "Consultar Utilizadores",
                                    icon: "mdi-magnify",
                                    canShow: this.$store.getters.hasPermission(['super', 'users.read']),
                                    items: [],
                                    exact: true,
                                    newTab: false,
                                },
                                {
                                    path: "/super/utilizadores/registar",
                                    name: "Registar Utilizador",
                                    icon: "mdi-plus-circle",
                                    items: [],
                                    exact: false,
                                    newTab: false,
                                    canShow: this.$store.getters.hasPermission(['super', 'users.create']),
                                    
                                },
                            ],
                            exact: true
                        },
                        {
                            path: "/super/roles",
                            name: "Roles",
                            icon: "mdi-shield-key",
                            exact: false,
                            canShow: this.$store.getters.hasPermission(['super', 'roles.read', 'roles.create']),
                            items: [
                                {
                                    path: "/super/roles",
                                    name: "Consultar Roles",
                                    icon: "mdi-magnify",
                                    items: [],
                                    newTab: false,
                                    canShow: this.$store.getters.hasPermission(['super', 'roles.read']),
                                    exact: true
                                },
                                {
                                    path: "/super/roles/registar",
                                    name: "Registar Role",
                                    icon: "mdi-plus-circle",
                                    items: [],
                                    newTab: false,
                                    canShow: this.$store.getters.hasPermission(['super', 'roles.create']),
                                    exact: false
                                },
                            ],
                            exact: true
                        },                        
                        {
                            
                            path: "/super/logs",
                            name: "Logs",
                            icon: "mdi-airplane",
                            items: [],
                            exact: true,
                            newTab: false,
                            canShow: this.$store.getters.hasPermission(['super', 'logs.read']),
                                
                        }
                    ],
                    exact: true
                },
                {
                    
                    path: "/exports",
                    name: "Exportações",
                    icon: "mdi-paperclip",
                    items: [],
                    exact: true,
                    newTab: false,
                    canShow: this.$store.getters.hasPermission(['super', 'exports.create']),
                        
                },
                {
                    
                    path: "/imports",
                    name: "Importações",
                    icon: "mdi-paperclip",
                    items: [],
                    exact: true,
                    newTab: false,
                    canShow: this.$store.getters.hasPermission(['super', 'import.guarantees', 'import.work_units', 'import.mqt']),
                        
                }
            ];
        }
    }
}
</script>
<style scoped>
.custom-scrollbar {
    scrollbar-width: thin; /* Para navegadores compatíveis */
    scrollbar-color: white transparent; /* Barra de rolagem branca */
}

/* Barra de rolagem personalizada para navegadores baseados em WebKit */
.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: transparent; /* Fundo transparente */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #1a237e; /* Cor azul escuro */
    border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #0d153f; /* Escurece um pouco ao passar o mouse */
}

.custom-scrollbar::-webkit-scrollbar-button {
    display: none; /* Remove setas de rolagem */
}
</style>
import API from "./API";

export default class Colaboradores extends API
{
    static async getWorkingDays(params) {
        return this.get("api/admin/colaboradores/getWorkingDays", {params: params});
    }

    static async getWorkersInDay(params){
        return this.get("api/admin/colaboradores/getWorkersInDay", {params: params})
    }

    static async getFaultsInDay(params){
        return this.get("api/admin/colaboradores/getFaultsInDay", {params: params})
    }

    static async getFaultsInMonth(params){
        return this.get("api/admin/colaboradores/getFaultsInMonth", {params: params});
    }
}
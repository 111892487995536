<template>
    <section >
        <v-row class="d-none d-lg-block  mx-1 px-1">
            <v-progress-linear v-if="loading"
                indeterminate
                class="global-loader"
            ></v-progress-linear>
            <v-col cols="12" offset="0" md="12" offset-md="0">
                <section v-if="!loading">
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    light
                    :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
                    ></v-breadcrumbs>
            
                    <h1 class="primary--text">
                        <v-icon color="primary">mdi-hard-hat</v-icon> <span>Registo Diário Manobrador</span>
                    </h1>

                    <v-btn @click="$router.push('/dashboard')" style="float: right;" class="primary">
                      <v-icon class="mr-2">mdi-view-dashboard</v-icon>
                      Dashboard
                    </v-btn>

                    <v-btn v-if="canApprove.flag" @click="confirmApprove" style="float: left;" class="success">
                      <v-icon class="mr-2">mdi-check-decagram</v-icon>
                      Aprovar diária
                    </v-btn>
            
                    <div class="mt-16">
                    <v-progress-linear v-if="loading"
                        indeterminate
                        class="global-loader"
                    ></v-progress-linear>
            
                    <template v-if="loading">
                        <v-row>
                        <v-col cols="12" md="6">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        </v-row>
                        <v-row>
                        <v-col cols="12">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        </v-row>
                        <v-row>
                        <v-col cols="12">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        </v-row>
                        <v-row class="mt-6" align="center" justify="space-around">
                        <v-skeleton-loader
                            type="button"
                            ></v-skeleton-loader>
                        </v-row>
                    </template>
                        <Operator method="update" :diary="diary"></Operator>
                
                    </div>
                </section>
            </v-col>
        </v-row>
        <v-row class="d-block d-lg-none">
            <v-progress-linear v-if="loading"
                indeterminate
                class="global-loader"
            ></v-progress-linear>
            <v-col cols="12" offset="0" md="12" offset-md="0">
                <section class="mt-16" v-if="!loading">
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    light
                    :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
                    ></v-breadcrumbs>
            
                    <h1 class="primary--text">
                    <v-icon color="primary">mdi-hard-hat</v-icon> <span>Registo Diário Manobrador</span>
                    </h1>
            
                    <div class="mt-16">
                    <v-progress-linear v-if="loading"
                        indeterminate
                        class="global-loader"
                    ></v-progress-linear>
            
                    <template v-if="loading">
                        <v-row>
                        <v-col cols="12" md="6">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        </v-row>
                        <v-row>
                        <v-col cols="12">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        </v-row>
                        <v-row>
                        <v-col cols="12">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        </v-row>
                        <v-row class="mt-6" align="center" justify="space-around">
                        <v-skeleton-loader
                            type="button"
                            ></v-skeleton-loader>
                        </v-row>
                    </template>
                        <Operator method="update" :diary="diary"></Operator>
                
                    </div>
                </section>
            </v-col>
        </v-row>
    </section>
  </template>
  <script>
  import Operator from '@/components/Diaries/Operator/Operator.vue'
  import PDEq from "@/api/PDEq.js";
  import PDMO from "@/api/PDMO.js";
  import Diaries from "@/api/Diaries.js";
  import DialogConfirmation from '@/components/UI/Modals/DialogConfirmation.vue';

  
  export default {
    components: {
        Operator,
        DialogConfirmation
    },
    data: () => ({
        canApprove: {
            flag: false,
            object: null
        },
        diary: {},
        loading:true, 
        confirmationModal:{
            show: false,
            title: '',
            message: '',
            approvalItem: null
        }, 
    }),
    mounted(){  
        let params={
            id_user: this.$route.params.id_user,
            hash: this.$route.params.hash,
            type: this.$route.params.type
        }

        Diaries.find(params).then((resp)  =>  {
            this.diary = resp.data
            this.loading = false
        })        

    },
    methods:{
        checkIfCanApprove(){
            if(this.$cookies.isKey("can_approve_diary_cookie")){
                this.canApprove.flag = this.$cookies.get("can_approve_diary_cookie")
                this.canApprove.object = this.$cookies.get("can_approve_diary_object_cookie")
                this.$cookies.remove("can_approve_diary_cookie");
                this.$cookies.remove("can_approve_diary_object_cookie");
            }else{
                this.canApprove.flag = false
                this.canApprove.object = null
            }
        },
        confirmApprove(){
            // Esta linha é a que impede a aprovação com a integração do JOSÉ ALVES
            /*if(approval.all_lines_with_costs == false){
                return
            }*/

            this.confirmationModal.title = 'Tem a certeza?'
            this.confirmationModal.message = 'Tem a certeza que pretende aprovar a diária?'
            this.confirmationModal.show = true
        },
        async aprovarDiaria(){
                let approval = this.canApprove.object
                
                if(approval != null){
                    // Send hash from Header
                    await Approval.approve(approval)
                        .then(response => {
                            
                            this.confirmationModal.show = false

                            window.location.reload()

                        }).catch(err => {
                            reject(err);
                            
                        }); 
                    
                }else{
                    this.error_message = 'Um erro aconteceu ao aprovar a diária! Por favor tente mais tarde'
                    this.error = true
                    this.confirmationModal.item = null
                    this.confirmationModal.show = false
                }
        },
        refresh(){
            
        }
    },
    computed: {
      crumbs: function() {
        return [
            {
                text: 'Registo Diário Manobrador',
                disabled: true,
                to: '#',
                exact: true,
            },
        ];
      }
    }
  };
  </script>
  
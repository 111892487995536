var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mt-16"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","offset":"0","md":"10","offset-md":"1"}},[_c('v-breadcrumbs',{staticClass:"pl-0",attrs:{"large":"","items":_vm.crumbs,"divider":"/"}}),_c('h1',{staticClass:"primary--text"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-apps-box")]),_vm._v(" "),_c('span',[_vm._v("Diárias KPI")])],1),_c('v-divider'),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.obras_user_tem_acesso,"label":"Obra","item-value":"Id","item-text":function (item) { return item.Codigo + ' - ' + item.Descricao; },"dense":"","outlined":""},model:{value:(_vm.filter.work_id),callback:function ($$v) {_vm.$set(_vm.filter, "work_id", $$v)},expression:"filter.work_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"d-none d-lg-block",attrs:{"label":"Data Inicio","outlined":"","dense":"","readonly":""},model:{value:(_vm.filter.data_inicio),callback:function ($$v) {_vm.$set(_vm.filter, "data_inicio", $$v)},expression:"filter.data_inicio"}},'v-text-field',attrs,false),on)),_c('v-text-field',{staticClass:"d-block d-lg-none",attrs:{"type":"date","id":"birthday","label":"Data Inicio","dense":"","outlined":"","max":new Date(new Date()+1).toISOString().substr(0, 10)},model:{value:(_vm.filter.data_inicio),callback:function ($$v) {_vm.$set(_vm.filter, "data_inicio", $$v)},expression:"filter.data_inicio"}})]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"allowed-dates":function (date) { return date <= new Date(new Date()+1).toISOString().substr(0, 10); }},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.filter.data_inicio),callback:function ($$v) {_vm.$set(_vm.filter, "data_inicio", $$v)},expression:"filter.data_inicio"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"d-none d-lg-block",attrs:{"label":"Data Fim","outlined":"","dense":"","readonly":""},model:{value:(_vm.filter.data_fim),callback:function ($$v) {_vm.$set(_vm.filter, "data_fim", $$v)},expression:"filter.data_fim"}},'v-text-field',attrs,false),on)),_c('v-text-field',{staticClass:"d-block d-lg-none",attrs:{"type":"date","id":"birthday","label":"Data Fim","dense":"","outlined":"","max":new Date(new Date()+1).toISOString().substr(0, 10)},model:{value:(_vm.filter.data_fim),callback:function ($$v) {_vm.$set(_vm.filter, "data_fim", $$v)},expression:"filter.data_fim"}})]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"allowed-dates":function (date) { return date <= new Date(new Date()+1).toISOString().substr(0, 10); }},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.filter.data_fim),callback:function ($$v) {_vm.$set(_vm.filter, "data_fim", $$v)},expression:"filter.data_fim"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{staticClass:"success",on:{"click":_vm.searchKPIDiary}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)],1),_c('div',{staticStyle:{"position":"relative"}},[_c('v-container'),_c('v-data-table',{staticClass:"mt-16",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.tableFooter,"options":_vm.options,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.obra",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.obra == null ? '-' : item.obra.Descricao)+" ")]}},{key:"item.frente",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.frente == null ? '-' : item.frente.Descricao)+" ")]}},{key:"item.classe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.classe == null ? '-' : item.classe.Descricao)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('IconView',{staticClass:"mr-2",on:{"click":function($event){return _vm.viewData(item)}}})],1)],1)]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"width":"80%","height":"80%"},model:{value:(_vm.previewDiary.show),callback:function ($$v) {_vm.$set(_vm.previewDiary, "show", $$v)},expression:"previewDiary.show"}},[_c('v-card',[(_vm.previewDiary.show)?_c('PreviewKPIDiary',{attrs:{"diary_id":_vm.previewDiary.id}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container fluid>
    <h2>Capturar Fotos para Envio</h2>

    <!-- Botão para abrir a câmera -->
    <v-btn v-if="!isCameraActive" class="primary" @click="openCamera('environment')">
      <v-icon class="mr-2">mdi-camera</v-icon>Abrir câmara
    </v-btn>

    <!-- Controles de câmera -->
    <div v-if="isCameraActive">
      <v-btn fab class="primary" @click="switchCamera">
        <v-icon v-if="activeCamera === 'environment'">mdi-camera-front</v-icon>
        <v-icon v-else>mdi-camera-rear</v-icon>
      </v-btn>
      <v-btn fab class="error" @click="closeCamera">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <!-- Visualização do feed da câmera -->
    <v-row v-if="isCameraActive">
      <v-col cols="12">
        <video ref="video" autoplay playsinline style="width: 100%; max-height: 400px; object-fit: cover;"></video>
        <v-btn @click="capturePhoto" color="success" block>Tirar Foto</v-btn>
      </v-col>
    </v-row>

    <!-- Visualização de imagens capturadas -->
    <v-card class="elevation-0" v-if="fields.invoices.length > 0">
      <v-card-title class="primary white--text">Imagens capturadas</v-card-title>
      <v-card-text>
        <v-row v-if="fields.invoices.length > 0">
          <v-col v-for="(image, index) in fields.invoices" :key="index" cols="12" md="3">
            <img :src="image" alt="Imagem capturada" style="width: 100%; height: auto; margin-bottom: 10px;">
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Botão para enviar as imagens -->
    <v-btn  v-if="fields.invoices.length > 0" @click="sendFiles" :disabled="fields.invoices.length === 0" color="success" block>
      Enviar Fotos
    </v-btn>
  </v-container>
</template>

<script>
import Invoices from '@/api/Invoices.js'

export default {
  name: "Fatura",
  data() {
    return {
      fields: {
        invoices: [], // Array para armazenar as imagens capturadas
      },
      isCameraActive: false, // Controle se a câmera está ativa
      videoStream: null, // Stream de vídeo
      activeCamera: "environment", // Define a câmara ativa (traseira por padrão)
    };
  },
  methods: {
    // Ativa a câmera
    async openCamera(facingMode = "environment") {
      try {
        // Certifique-se de fechar a câmera atual antes de abrir outra
        if (this.videoStream) {
          this.closeCamera();
        }

        this.isCameraActive = true; // Ativa o estado da câmera
        await this.$nextTick(); // Aguarda o DOM ser atualizado

        // Verifique se o elemento de vídeo está disponível
        const videoElement = this.$refs.video;
        if (!videoElement) {
          throw new Error("Elemento de vídeo não encontrado.");
        }

        // Preferência para a câmera traseira ou frontal
        const constraints = {
          video: { facingMode: facingMode },
        };

        this.videoStream = await navigator.mediaDevices.getUserMedia(constraints);

        // Conecte o stream ao elemento de vídeo
        videoElement.srcObject = this.videoStream;
        videoElement.play();
        this.activeCamera = facingMode; // Define a câmera ativa
      } catch (error) {
        console.error(`Erro ao acessar a câmera (${facingMode}):`, error.message);

        // Tentativa de fallback para a câmera frontal
        if (facingMode === "environment") {
          console.log("Tentando abrir a câmera frontal como fallback...");
          this.openCamera("user");
        } else {
          alert("Erro ao acessar a câmera: " + error.message);
        }

        this.isCameraActive = false;
      }
    },


    // Alterna entre a câmara traseira e a frontal
    switchCamera() {
      const nextCamera = this.activeCamera === "environment" ? "user" : "environment";
      this.openCamera(nextCamera);
    },

    // Captura uma foto
    capturePhoto() {
      const video = this.$refs.video;
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Adicionar a imagem capturada ao array
      this.fields.invoices.push(canvas.toDataURL("image/png"));
    },

    // Envia as imagens ao servidor
    async sendFiles() {
      const formData = new FormData();
      this.fields.invoices.forEach((image, index) => {
        formData.append(`images[${index}]`, image);
      });

      try {
        const response = await Invoices.create(formData);
        console.log(response);
      } catch (error) {
        console.error("Erro ao enviar fotos:", error);
      }
    },

    // Desativa a câmera quando não for mais necessária
    closeCamera() {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach((track) => track.stop());
        this.videoStream = null;
        this.isCameraActive = false;
      }
    },
  },
  beforeDestroy() {
    this.closeCamera(); // Para o stream de vídeo ao destruir o componente
  },
};
</script>


<style>
video {
  width: 100%; /* Ocupa toda a largura */
  height: auto; /* Mantém a proporção */
  object-fit: cover; /* Preenche o espaço sem distorção */
}

img {
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>

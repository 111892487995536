import * as XLSX from "xlsx";

export function readExcelFile(file) {
    return new Promise((resolve, reject) => {
        if (!file) {
            reject(new Error("Nenhum ficheiro foi selecionado."));
            return;
        }

        // Verifica se o ficheiro tem a extensão correta
        const validExtensions = ["xlsx", "xls"];
        const fileExtension = file.name.split(".").pop().toLowerCase();

        if (!validExtensions.includes(fileExtension)) {
            reject(new Error("Por favor, escolha um ficheiro Excel (.xls ou .xlsx)."));
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: "array" });

                // Obtém a primeira folha
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                // Converte os dados para JSON (header: 1 mantém a estrutura de array)
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                resolve(jsonData);
            } catch (error) {
                reject(new Error("Erro ao processar o ficheiro Excel."));
            }
        };

        reader.onerror = () => reject(new Error("Erro ao ler o ficheiro."));
        reader.readAsArrayBuffer(file);
    });
}

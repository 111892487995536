<template>
    <section class="mt-16">
      <v-row>
        <v-col cols="12" offset="0" md="10" offset-md="1">    
          <v-breadcrumbs
            large
            :items="crumbs"
            divider="/"
            class="pl-0"
          ></v-breadcrumbs>
    
          <h1 class="primary--text">
            <v-icon color="primary">mdi-apps-box</v-icon> <span>Diárias KPI</span>
          </h1>
          <v-divider></v-divider>

          <v-row class="mt-4">
            <v-col cols="12" md="4">
              <v-autocomplete
                :items="obras_user_tem_acesso"
                label="Obra"
                item-value="Id"
                v-model="filter.work_id"
                :item-text="item => item.Codigo + ' - ' + item.Descricao"
                dense outlined
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                  <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      class="d-none d-lg-block"
                      v-model="filter.data_inicio"
                      label="Data Inicio"
                      outlined dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                  <v-text-field 
                      class="d-block d-lg-none" 
                      v-model="filter.data_inicio" 
                      type="date" 
                      id="birthday" 
                      label="Data Inicio"
                      dense outlined
                      :max="new Date(new Date()+1).toISOString().substr(0, 10)"
                  />
                  
                  </v-date-picker>
                  </template>
                  <v-date-picker
                      :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                      v-model="filter.data_inicio"
                      @input="menu1 = false"
                  ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="2">
              <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                  <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      class="d-none d-lg-block"
                      v-model="filter.data_fim"
                      label="Data Fim"
                      outlined dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                  <v-text-field 
                      class="d-block d-lg-none" 
                      v-model="filter.data_fim" 
                      type="date" 
                      id="birthday" 
                      label="Data Fim"
                      dense outlined
                      :max="new Date(new Date()+1).toISOString().substr(0, 10)"
                  />
                  
                  </v-date-picker>
                  </template>
                  <v-date-picker
                      :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                      v-model="filter.data_fim"
                      @input="menu2 = false"
                  ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="1">
              <v-btn 
                  class="success"
                  @click="searchKPIDiary"
              >
                  <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          
          <div style="position:relative">
            <v-container>

            </v-container>
    
            <v-data-table
              :headers="headers"
              :items="items"
              :server-items-length="total"
              :loading="loading"
              :footer-props="tableFooter"
              :options.sync="options"
              class="mt-16"
              locale="pt-pt"
            >
              <template v-slot:item.obra="{ item }">
                {{ item.obra == null ? '-' : item.obra.Descricao }}
              </template>

              <template v-slot:item.frente="{ item }">
                {{ item.frente == null ? '-' : item.frente.Descricao }}
              </template>

              <template v-slot:item.classe="{ item }">
                {{ item.classe == null ? '-' : item.classe.Descricao }}
              </template>

              <template v-slot:item.funcionario="{ item }">
                {{ item.funcionario == null ? '-' : item.funcionario.funcionario_codigo }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-row>
                  <v-col cols="12" md="auto">
                    <IconView
                        class="mr-2"
                        @click="viewData(item)"
                    />
                  </v-col>
                  <v-col cols="12" md="auto">
                    <v-btn fab x-small outlined color="blue" @click="showPreviewDiary(item.id)">
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

              </template>
              
            </v-data-table>
          </div>
        </v-col>
      </v-row>
      <v-dialog width="80%" height="80%" v-model="previewDiary.show">
          <v-card>
              <PreviewKPIDiary v-if="previewDiary.show" :diary_id="previewDiary.id" />
          </v-card>
      </v-dialog>

    </section>
  </template>
  <script>
  import dayjs from 'dayjs';
  import User from '@/api/User.js'
  import DiaryKPI from "@/api/DiaryKPI.js";
  import IconView from '@/components/UI/IconView.vue';
  import PreviewKPIDiary from "@/views/Diaries/Preview/PreviewKPIDiary.vue"
  
  export default {
    components: {
      IconView,
      PreviewKPIDiary
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchKPIDiary()}, 10);
        },
        deep: true,
      },
    },
    beforeCreate(){
      document.title = "ODC - Diárias KPI";
    },
    data: () => ({  
      menu1: false,
      menu2: false,
      obras_user_tem_acesso: [],    
      headers: [
        {
          text: 'Data',
          sortable: true,
          value: 'data',
          align: "center"
        },
        {
          text: 'Obra',
          sortable: true,
          value: 'work_name',
          align: "center"
        },
        {
          text: 'Frente',
          sortable: true,
          value: 'frente_name',
          align: "center"
        },
        /*{
          text: 'Classe',
          sortable: true,
          value: 'classe_name',
          align: "center"
        },*/
        {
          text: 'Funcionário',
          sortable: true,
          value: 'funcionario',
          align: "center"
        },
        {
          text: "Opções",
          value: "actions",
          align: "center"
        }
      ],
      items: [],
      total: 0,
      loading: false,
      searching: false,
      tableFooter: {
        'items-per-page-options': [5, 10, 15, 20],
        'items-per-page-text': "Linhas por página"
      },
      options: {},
      speedDial:false,
      filter: {
        work_id: null,
        data_inicio: null,
        data_fim: null
      },
      previewDiary:{
        id: null,
        show: false
      }
    }),
    mounted(){
  
      if(!this.$store.getters.hasPermission(["super", "kpi_diaries.read"])) {
        this.$router.push('/forbidden');
      }
  
      this.fillBaseData();
    },
    methods:{  
      showPreviewDiary(item){
        this.previewDiary.id = item
        this.previewDiary.show = true
      },
      fillBaseData() {
  
        if(localStorage["kpi-diaries-list-" + this.$store.state.user.data.id] != undefined){
  
          let data = JSON.parse(localStorage["kpi-diaries-list-" + this.$store.state.user.data.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }

        User.getUserObras(this.$store.state.user.data.id).then((resp)    =>  {
            this.obras_user_tem_acesso = resp.data
        })

      },
      searchKPIDiary(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
  
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };
      
        Object.assign(request, filter);
  
        localStorage["work_work_unit-list-" + this.$store.state.user.data.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        this.loading = false
        DiaryKPI.search(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
          this.loading = false;
  
        });
      },
      viewData(item){
        this.$router.push('/kpi/edit/' + item.id);
      },
      resetDiaryKPI()
      {
        this.filter = {
        };
  
        this.searchKPIDiary();
      },
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Diárias KPI',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  
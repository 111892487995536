var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"bg-image",attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[(_vm.showMaintenance)?_c('v-dialog',{attrs:{"max-width":"350px"},model:{value:(_vm.showMaintenance),callback:function ($$v) {_vm.showMaintenance=$$v},expression:"showMaintenance"}},[_c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"elevation-0 ma-0 pa-0"},[_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" Site em Manutenção ")]),_c('v-card-text',{staticClass:"mt-2 text-center"},[_vm._v(" O seu login não será bem sucedido enquanto o site estiver em manutenção. Agradecemos a sua compreensão! ")]),_c('v-card-text',{staticClass:"justify-center"},[_c('v-avatar',{staticStyle:{"margin":"auto","display":"block"},attrs:{"height":"105","width":"300","tile":""}},[_c('v-img',{attrs:{"src":require("@/assets/Maintenance/manutencao.png"),"lazy-src":require("@/assets/Maintenance/manutencao.png"),"contain":""}})],1)],1),_c('v-card-text',{staticClass:"text-center"},[_vm._v(" Tentaremos ser o mais breves possível! ")])],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"mx-auto my-12 align-self-center pa-5",attrs:{"loading":_vm.loading}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{staticClass:"mb-8",attrs:{"color":"primary","indeterminate":""}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-avatar',{staticClass:"mt-2 mb-2",attrs:{"height":"105","width":"300","tile":""}},[_c('img',{attrs:{"src":require("@/assets/fundo_conduril.png")}})])],1),_c('v-row',[_c('v-divider')],1),_c('v-card-title',{staticClass:"mt-2 primary--text justify-center"},[_vm._v("ODC - Login")]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"login",attrs:{"disabled":_vm.loading,"submitText":"Login"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card-text',[_c('v-row',{staticClass:"mx-0",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-btn',{staticClass:"font-weight-bold center-button",attrs:{"rounded":"","block":"","dark":"","color":"#1e3582"},on:{"click":_vm.microsoftLogin}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-microsoft")]),_vm._v(" "+_vm._s(_vm.$msal.isAuthenticated() ? _vm.user.userName : 'Usar conta Microsoft')+" ")],1)],1),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"12","md":"8","align":"center"}},[(_vm.$msal.isAuthenticated())?_c('v-btn',{attrs:{"rounded":"","depressed":"","color":"#1e3582","dark":"","outlined":""},on:{"click":function($event){return _vm.$msal.signOut()}}},[_c('v-icon',[_vm._v("mdi-logout")]),_vm._v(" Logout ")],1):_vm._e()],1),_c('v-col',{staticClass:"mt-2 mb-2",attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"flat":""}},[_c('v-expansion-panel',{staticStyle:{"border":"1px solid rgba(0, 0, 0, 0.12) !important","border-radius":"4px"}},[_c('v-expansion-panel-header',{staticClass:"justify-space-between"},[_c('span',{staticClass:"text-left text-h6"},[_vm._v("Login com credenciais")])]),_c('v-expansion-panel-content',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"text-white center-button",attrs:{"color":"#111b2d","label":"Email\\Username","dense":"","outlined":"","prepend-inner-icon":"mdi-account","error-messages":errors},model:{value:(_vm.fields.email),callback:function ($$v) {_vm.$set(_vm.fields, "email", $$v)},expression:"fields.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"text-white center-button",attrs:{"color":"#111b2d","dense":"","outlined":"","type":"password","label":"Password","prepend-inner-icon":"mdi-lock","error-messages":errors},model:{value:(_vm.fields.password),callback:function ($$v) {_vm.$set(_vm.fields, "password", $$v)},expression:"fields.password"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){_vm.reset_password.opened = !_vm.reset_password.opened}}},[_vm._v(" Esqueci-me da Password ")])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticStyle:{"display":"block","margin":"auto"},attrs:{"type":"submit","color":"primary","disabled":invalid}},[_vm._v(" Login ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)]}}])}),_c('v-row',{staticClass:"mt-5",attrs:{"justify":"center"}},[_c('div',{staticClass:"col-12 text-center pb-0"},[_c('p',{staticClass:"mb-0"},[_vm._v("Powered By")])]),_c('a',{attrs:{"target":"_blank","href":"https://www.dreamdealtech.pt/"}},[_c('v-avatar',{staticClass:"mt-2 mb-2",attrs:{"height":"45","width":"140","tile":""}},[_c('img',{attrs:{"src":require("@/assets/logo.png")}})])],1)])],2)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.reset_password.opened),callback:function ($$v) {_vm.$set(_vm.reset_password, "opened", $$v)},expression:"reset_password.opened"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"h5"},[_vm._v("Reset Password")])]),_c('v-card-text',[(!_vm.reset_password.reseted)?_c('validation-observer',{ref:"reset",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.reset.apply(null, arguments)}}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"email","name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-at","label":"Email","type":"email","clearable":"","error-messages":errors},model:{value:(_vm.reset_password.email),callback:function ($$v) {_vm.$set(_vm.reset_password, "email", $$v)},expression:"reset_password.email"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{staticClass:"mr-5 ml-5 mb-5",attrs:{"disabled":invalid,"depressed":"","color":"primary","type":"submit"}},[_vm._v(" Enviar email ")])],1)],1)],1)],1)]}}],null,false,2612561685)}):_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","offset-md":"3"}},[_c('h2',{staticClass:"success--text text-center"},[_vm._v("Email enviado!")]),_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","justify":"space-around"}},[_c('v-icon',{staticClass:"text-center",attrs:{"large":"","color":"success"}},[_vm._v(" mdi-checkbox-marked-circle-outline ")])],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.reset_password.opened = false}}},[_vm._v(" Fechar ")])],1)],1)],1),_c('ErrorSnackbar',{attrs:{"timeout":4000,"message":_vm.error_message},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
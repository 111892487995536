import API from "./API";

export default class ExternalUserEquipment extends API
{
    static async getExternalEquipment(filter) {
        return this.get("api/admin/external-user", {params: filter});
    }
    
    static async create(data){
        return this.post("api/admin/external-user/", data);
    }
}
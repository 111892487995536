<template>
    <v-container fluid>
      <v-row>
        <v-col cols="12" offset="0" md="10">
          <h2 class="primary--text">Colaboradores</h2>
          <v-breadcrumbs
            large
            :items="crumbs"
            divider="/"
            class="pl-0"
          ></v-breadcrumbs>
        </v-col>
      </v-row>
  
      <v-row>
        <v-col cols="12" v-if="obra != null" class="text-h4 primary white--text rounded" align="center">
          {{obra.Codigo}} - {{obra.Descricao}}
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            v-model="fields.year"
            :items="availableYears"
            dense
            outlined
            label="Ano"
            @change="searchWorkers(fields.month);reloadData++"
          ></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            v-model="fields.month"
            :items="availableMonths"
            dense
            outlined
            label="Mês"
            item-text="label"
            item-value="value"
            @change="searchWorkers(fields.month);reloadData++"
          ></v-select>
        </v-col>
      </v-row>
  
      <v-row :key="reloadData">
        <v-col cols="12">
          <v-expansion-panels accordion>
            <v-expansion-panel
              v-for="(day, index) in diasOrdenados"
              :key="index"
              @change="loadWorkers(day)"
            >
              <v-expansion-panel-header>
                <span>{{ formatarData(day) }} <v-icon color="warning" v-if="inDay(day)">mdi-alert-box</v-icon></span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card class="elevation-0">
                      <v-card-title class="primary white--text">
                        Colaboradores em diárias
                      </v-card-title>
                      <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="workersByDay"
                            :server-items-length="total"
                            :loading="loading"
                            :footer-props="tableFooter"
                            :options.sync="options"
                            locale="pt-pt"
                        >
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-card>
                      <v-card-title class="primary white--text">
                        Faltas
                      </v-card-title>
                      <v-card-text>            
                        <v-row no-gutters>
                          <v-col cols="12">       
                            <Faltas :key="reloadFaltas" :day="day" :work_id="$route.params.id" />
                          </v-col>
                          <v-col cols="12">                            
                            <v-autocomplete
                              class="mt-4"
                              outlined dense
                              :items="funcionarios_internos"
                              :item-text="item => item.Codigo + ' - ' + item.Nome"
                              item-value="Codigo"
                              label="Funcionário"
                              v-model="fault.funcionario"
                            />
                          </v-col>
                          <v-col cols="12">                            
                            <v-autocomplete
                              class="mt-2"
                              :item-text="item=>item.description"
                              item-value="id"
                              dense outlined
                              :items="fault_types"
                              v-model="fault.fault_type_id"
                              label="Falta"
                            />
                          </v-col>
                          <v-col cols="12" align="end">
                            <v-btn rounded @click="createFault(day)" class="success" small>
                              Submeter
                            </v-btn>
                          </v-col>
                        </v-row>            
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <DialogConfirmation 
          v-if="confirmationModal.show"
          :title="confirmationModal.title" 
          type="warning" 
          :opened="confirmationModal.show" 
          :elevation="0"
          @on-submit="confirmFaultCreation" 
          @on-cancel="confirmationModal.item.fault_type_id = null;confirmationModal.item = null; confirmationModal.show = false;">
              {{ confirmationModal.message }}
      </DialogConfirmation>

    </v-container>
  </template>
  
  <script>
  import Colaboradores from '@/api/Colaboradores.js'
  import Faults from '@/api/Faults.js'
  import DialogConfirmation from '@/components/UI/Modals/DialogConfirmation.vue';
  import Faltas from '@/components/Colaboradores/Faltas.vue'
  import Obra from '@/api/Obra.js'

  export default {
    name: "Colaboradores",
    components:{
      DialogConfirmation,
      Faltas
    },
    data() {
      const hoje = new Date();
      return {
        reloadFaltas: 1000,
        reloadData: 0,
        obra: null,
        faults_by_day: [],
        loading: true,
        confirmationModal:{
          item: null,
          show: false,
          message: 'Tem a certeza que deseja marcar falta a este user?'
        },
        fault:{
          fault_type_id: null,
          funcionario: null,
          day: null
        },
        fault_types: [],
        funcionarios_internos: [],
        fields: {
          works: null,
          year: hoje.getFullYear(),   // <- ano atual
          month: hoje.getMonth()      // <- mês atual (0-indexado)
        },
        workingDays: [],
        workersByDay: [],
        mesesPorExtenso: [],
        options: {},
        total: 0,
        tableFooter: {
          'items-per-page-options': [5, 10, 15, 20],
          'items-per-page-text': "Linhas por página"
        },
        headers: [
          { text: 'Nome', value: 'nome' },
        ]
      }
    },
    created() {
      this.mesesPorExtenso = [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
      ];
    },
    mounted() {
      Obra.getObra(this.$route.params.id).then((resp) =>  {
        this.obra = resp.data.obra
      })
      this.fillBaseData()
    },
    methods: {
      inDay(day){
        return this.faults_by_day.includes(day);
      },
      confirmFaultCreation(){
        let data = {...this.confirmationModal.item}
        data.CodigoMO = data.funcionario
        data.DataMov = data.day
        Faults.createFault(data).then((resp)  =>  {
          this.confirmationModal.show = false
          this.reloadFaltas++
          this.fault.funcionario = null
          this.fault.fault_type_id = null
        })
      },
      createFault(day){
        this.fault.day = day
        this.confirmationModal.item = this.fault; 
        this.confirmationModal.show = true        
      },
      organizarDiasMes() {
        const dias = [];
        const ano = this.fields.year;
        const mes = this.fields.month;

        // último dia do mês
        const ultimoDia = new Date(ano, mes + 1, 0).getDate();

        for (let dia = 1; dia <= ultimoDia; dia++) {
          const data = new Date(ano, mes, dia);
          const yyyy = data.getFullYear();
          const mm = (data.getMonth() + 1).toString().padStart(2, '0');
          const dd = data.getDate().toString().padStart(2, '0');
          dias.push(`${yyyy}-${mm}-${dd}`);
        }

        this.workingDays = dias;
      },
      searchWorkers(month = null) {
        const mesAtual = new Date().getMonth();
        const anoAtual = new Date().getFullYear();
  
        this.organizarDiasMes(); // gera os dias do mês
      },
      async fillBaseData() {
        this.searchWorkers()
        Faults.getTypes().then((resp) =>  {
          this.fault_types = resp.data
        })        
        let data = {
          id: this.$route.params.id
        }
        Obra.getObraFuncionarios(data).then((resp)  =>  {
          this.funcionarios_internos = resp.data
        })
        data = {
          year: this.fields.year,
          month: this.fields.month + 1
        }
        Colaboradores.getFaultsInMonth(data).then((resp)  =>  {
          this.faults_by_day = resp.data
        })
      },
      formatarData(data) {
        const d = new Date(data)
        const dia = d.getDate().toString().padStart(2, '0')
        const mes = this.mesesPorExtenso[d.getMonth()]
        const ano = d.getFullYear()
        return `${dia} de ${mes} de ${ano}`
      },
      loadWorkers(day) {
        if (this.workersByDay[day]) return
  
        this.loading = true
        const d = new Date(day)
  
        let data = {
          day: d.getDate(),
          month: d.getMonth()+1,
          year: d.getFullYear(),
          work_id: this.$route.params.id
        }
  
        Colaboradores.getWorkersInDay(data).then(resp => {
          this.workersByDay = resp.data.data
          this.total = resp.total
          this.loading = false
        })
      }
    },
    computed: {
      crumbs() {
        return [
          {
            text: 'Colaboradores',
            disabled: true,
            to: '#',
            exact: true,
          },
        ];
      },
      availableYears() {
        const anoAtual = new Date().getFullYear();
        const anos = [];
        for (let ano = anoAtual; ano >= 2024; ano--) {
          anos.push(ano);
        }
        return anos;
      },
      availableMonths() {
        const mesAtual = new Date().getMonth(); // 0-indexado
        const mesesParaMostrar = this.fields.year === new Date().getFullYear()
          ? mesAtual + 1 // inclui o mês atual
          : 12;

        return Array.from({ length: mesesParaMostrar }, (_, index) => ({
          value: index,
          label: this.mesesPorExtenso[index]
        }));
      },
      diasOrdenados() {
        return [...this.workingDays].sort((a, b) => new Date(b) - new Date(a));
      }
    }
  }
  </script>
  
<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <h2 class="primary--text">Atualizar utilizador</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-divider class="mb-12"></v-divider>



                <v-progress-linear
                    class="mb-8"
                    color="primary"
                    indeterminate
                    v-if="loading"
                ></v-progress-linear>
                <Form
                    ref="form"
                    submitText="Gravar"
                    @submited="updateUser"
                    :loading="loading"
                    v-if="loadForm"
                >
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-switch
                                inset
                                :color="fields.is_active ? 'success' : 'error'"
                                v-model="fields.is_active"
                                :label="(fields.is_active ? 'Ativo' : 'Inativo')"
                            ></v-switch>
                        </v-col>
                        <v-col cols="12" md="4">
                            <Input
                                v-model="fields.name"
                                type="name"
                                label="Nome"
                                dense outlined
                                rules="required"
                                vid="name"
                            />
                        </v-col>

                        <v-col cols="12" md="4">
                            <Input
                                v-model="fields.email"
                                type="email"
                                :label="fields.is_external ? 'Email' : 'Email *'"
                                dense outlined
                                :rules="fields.is_external ? 'email' : 'required|email'"
                                vid="email"
                            />
                        </v-col>

                        <v-col cols="12" md="2">
                            <Input
                                v-model="fields.telephone"
                                dense outlined
                                type="text"
                                label="Telefone"
                                vid="telephone"
                            />
                        </v-col>

                        <v-col cols="12" md="2">
                            <Input
                                v-model="fields.username"
                                type="name"
                                label="Username"
                                dense outlined
                                rules="|"
                                vid="Username"
                            />
                        </v-col>

                        <v-col cols="12" md="12" v-if="fields.is_external">
                            <v-autocomplete
                                dense outlined
                                :items="funcionarios_externos"
                                label="Funcionário Externo"
                                v-model="fields.funcionario"
                                item-value="Numero"
                                :item-text="item => item.Numero + ' - ' + item.Nome"
                            />
                        </v-col>

                        <v-col cols="12">
                            <MultipeSelect
                                :disabled="!fields.is_external == '0'"
                                v-model="fields.roles"
                                :items="roles"
                                dense outlined
                                item-text="display_name"
                                item-value="id"
                                label="Roles"
                                rules="required"
                                vid="roles"
                            />
                        </v-col>
                       
                        <v-col cols="12" md="4" v-if="fields.is_external == '1' || fields.is_external == false">
                            <SearchUserById 
                                dense outlined
                                :funcionarios="funcionarios"  
                                v-model="fields.funcionario"
                                :selectedFunc="fields.funcionario"  
                                @input="lookForVehicles"
                            />
                        </v-col>
                        

                        <v-col cols="12" md="8" v-if="fields.is_external == '1' || fields.is_external == false">
                            <SelectAutoComplete
                                v-model="fields.funcionario"
                                :items="funcionarios"
                                dense outlined
                                return-object
                                item-text="Nome"
                                item-value="Codigo"
                                label="Funcionario"
                                vid="funcionario"
                                @change="lookForVehicles"
                            />
                        </v-col>                        
                    </v-row>
                    <v-row v-if="$store.getters.hasPermission(['super', 'users.create'])">
                        <v-col cols="12" md="12" v-if="false">
                            <v-checkbox
                                v-model="onlyActivate"
                                :disabled="can_activate_sso"
                                label="Ativar login por SSO (só para domínios CONDURIL)"
                                dense outlined
                            />
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-checkbox
                                v-model="addPassword"
                                label="Alterar password"
                                dense outlined
                            />
                        </v-col>
                        <v-col cols="12" md="9" v-if="addPassword">
                            <Input
                                label="Password"
                                type="password"
                                dense outlined
                                placeholder="Insira Password"
                                rules="small_letter|password_size|special_char_1|capital_letter|one_number"
                                vid="password"
                                v-model="fields.password"
                                hint="Mínimo 8 carateres"
                            />
                        </v-col>
                    </v-row>

                    <v-row class="ma-0 pa-0" >
                        <v-col cols="12" md="6">
                            <v-card>
                                <v-card-title class="primary white--text">
                                    Obras associadas
                                </v-card-title>
                                <v-card-text class="ma-0 pa-0">
                                        <v-simple-table>
                                            <template v-slot:default>
                                            <thead class="warning">
                                                <tr>
                                                <th class="text-left white--text">
                                                    Código
                                                </th>
                                                <th class="text-left white--text">
                                                    Descrição
                                                </th>
                                                <th class="text-left white--text">
                                                    Opções
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                v-for="item in obras_user"
                                                :key="item.Id"
                                                >
                                                <td>{{ item.Codigo }}</td>
                                                <td>{{ item.Descricao }}</td>
                                                <td>
                                                    <IconRemove
                                                        v-if="$store.getters.hasPermission(['super', 'works.user_accesses_manage'])"
                                                        @on-submit="deleteObra(item)"
                                                    />                                                    
                                                </td>
                                                </tr>
                                            </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-row>
                                            <v-col cols="12" md="10">
                                                <v-autocomplete
                                                    :items="obras"
                                                    label="Associar obra"
                                                    v-model="obra_selected"
                                                    dense outlined
                                                    item-value="Id"
                                                    :item-text="item=> item.Codigo + ' - ' + item.Descricao"
                                                />
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-btn
                                                    icon
                                                    @click="adicionarObra"
                                                    :disabled="obra_selected == null"
                                                    class="primary"
                                                >
                                                    <v-icon color="white">mdi-plus</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col  v-if="fields.vehicles != null && isManobrador"
                                cols="12" md="12"
                            >
                            <v-card style="width:100%;" class="ma-0 pa-0 elevation-0" outlined>
                                <v-card-title class="primary white--text">
                                    Veículos Associados
                                </v-card-title>

                                <v-card-text v-if="fields.is_external == '0'">
                                    <v-list>
                                        <template v-for="(item, index) in getVehiclesInfo">
                                            <v-list-item
                                                v-if="item.Descricao"
                                            >
                                                <v-list-item-action>
                                                <v-icon small>mdi-truck</v-icon>
                                                </v-list-item-action>

                                                <v-list-item-content>
                                                    <v-list-item-title class="text-caption">{{ item.Descricao }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>

                                            <v-divider class="mb-0"></v-divider>
                                        </template>
                                    </v-list>
                                </v-card-text>

                                <v-card-text v-else>
                                    <v-row class="mt-4">
                                        <v-col cols="12" md="6">
                                            <v-autocomplete
                                                :items="obras_user"
                                                :item-text="item => item.Codigo + ' - ' + item.Descricao"
                                                item-value="Id"
                                                label="Obra"
                                                dense outlined
                                                v-model="obra_selecionada"
                                                @change="alteracao_obra"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-autocomplete
                                                label="Equipamento"
                                                v-model="equipamento_selecionado"
                                                :items="equipamentos_available"
                                                dense outlined
                                                item-value="equipment_id"
                                                :item-text="item=>  item.EntityKey + ' - ' + item.equipment_description"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <v-btn fab class="success" :disabled="obra_selecionada == null && equipamento_selecionado == null" @click="adicionarEquipamento" small>
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-list>
                                        <template v-for="(item, index) in veiculos_externos">
                                            <v-list-item
                                            >
                                                <v-list-item-action>
                                                <v-icon small>mdi-truck</v-icon>
                                                </v-list-item-action>

                                                <v-list-item-content>
                                                    <v-list-item-title class="text-caption">{{item.obra.Codigo }} {{ item.obra.Descricao }} - {{item.equipamento.EntityKey}} {{ item.equipamento.Descricao }}</v-list-item-title>
                                                    
                                                </v-list-item-content>
                                            </v-list-item>

                                            <v-divider class="mb-0"></v-divider>
                                        </template>
                                    </v-list>
                                </v-card-text>
                            </v-card>

                           
                        </v-col>
                    </v-row>
                    
                    
                </Form>

                <SuccessSnackbar message="Dados gravados!" v-model="success"/>

                <ErrorSnackbar v-model="error" :message="error_message"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import IconRemove from '@/components/UI/IconRemove.vue';
    import MenuTabs from "@/components/UI/Tabs/MenuTabs";

    import Form from "@/components/UI/Forms/Form";

    import Input from "@/components/UI/Inputs/Input";

    import MultipeSelect from "@/components/UI/Inputs/MultipeSelect";

    import Select from "@/components/UI/Inputs/Select";

    import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";

    import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";
    import SearchUserById from "@/components/UI/Inputs/SearchUserById"
    import SelectAutoComplete from "@/components/UI/Inputs/SelectAutoComplete";
    import EntidadePessoalAPI from '@/api/EntidadePessoal.js'
    import ExternalUserEquipment from '@/api/ExternalUserEquipment.js'

    import User from "@/api/User.js"
    import Obra from "@/api/Obra.js"
    import Works from "@/api/Works.js"

export default {
    components: {
        SearchUserById,
        SelectAutoComplete,
        IconRemove,
        MenuTabs,
        Form,
        Input,
        SuccessSnackbar,
        ErrorSnackbar,
        MultipeSelect,
      Select,
    },
    data: () => ({
        addPassword: false, 
        loading: false,
        loadForm: false,
        onlyActivate: false,
        obra_selecionada: null,
        can_activate_sso: false,
        isManobrador: false,
        fields: {
            name: null,
            email: null,
            telehpone: null,
            roles: [],
            username: null,
            funcionario: null,
            vehicles: null,
            password: null,
            let_active: false,
            is_active: false,
            is_external: null
        },
        obras: [],
        obra_selected: null,
        equipamento_selecionado: null,
        loading: false,
        success: false,
        error: null,
        obras_user: [],
        funcionarios_externos: [],
        equipamentos_available: [],
        roles: [],
        veiculos_externos: [],
        funcionarios: [],
        error_message: "",
    }),
    watch:{
        'fields.email':{
            immediate: true,
            handler(val){
                if(val == null)
                    return

                if(val.includes('@conduril.pt'))
                    this.can_activate_sso = false
                else this.can_activate_sso = true

                console.log(this.fields)
            }
        }
    },
    mounted: async function() {
        if(!this.$store.getters.hasPermission(["super", "users.update"])) {
            this.$router.push('/forbidden');
        }

        this.loading = true
        this.$store.dispatch("listRoles")
            .then(res => {
                this.roles = res.data;
            })

        this.$store.dispatch("listFuncionarios")
            .then(res => {
                this.funcionarios = res.data;

            })    

        await this.$store.dispatch("getUser", this.$route.params.id)
            .then((res) => {
                this.fields.name = res.data.name;

                this.fields.email = res.data.email;

                this.fields.is_active = res.data.is_active

                this.fields.username = res.data.username

                this.fields.telephone = res.data.telephone;

                this.fields.is_external = res.data.is_external

                this.fields.roles = res.data.roles.map(item => item.id)

                if(res.data.roles.map(item => item.name) == 'manobrador')
                    this.isManobrador = true
                
                if(res.data.funcionario != null){
                    this.fields.funcionario = res.data.funcionario

                    this.lookForVehicles()
                }

                this.loadForm = true.valueOf
            });

            this.loadUserObras()

            Obra.onlyWorks().then((resp)    =>  {
                this.obras = resp.data
            })

            EntidadePessoalAPI.all().then((resp)   =>  {
                this.funcionarios_externos = resp.data
            })



            this.fillVeiculosExternos()


            this.loading = false

    },
    methods: {
        fillVeiculosExternos(){
            if(this.fields.is_external == '1'){
                let params = {
                    user_id: this.$route.params.id
                }

                ExternalUserEquipment.getExternalEquipment(params).then((response)   =>  {
                    this.veiculos_externos = response.data
                })
            }  
        },
        adicionarEquipamento(){
            let params = {
                user_id: this.$route.params.id,
                equipment_id: this.equipamento_selecionado,
                work_id: this.obra_selecionada
            }

            ExternalUserEquipment.create(params).then((resp)    =>  {
                console.log(resp)
                this.fillVeiculosExternos()
            })
        },
        alteracao_obra(){
            this.equipamentos_available = []
            Works.getMachines(this.obra_selecionada).then((resp)    =>  {
                this.equipamentos_available = resp.data
            })
        },
        getExternalVehicles(){
            return []
        },
        deleteObra(item){
            this.loading = true
            let data = {
                work_id: item.Id,
                user_id: this.$route.params.id
            }
            Works.deleteUser(item.Id, data).then((resp)    =>  {
                this.loadUserObras();
                this.success = true
                this.loading = false
            })
        },
        loadUserObras(){  
            User.getUserObras(this.$route.params.id).then((resp)    =>  {
                this.obras_user = resp.data
                this.reloadObras++
            })
        },
        adicionarObra(){
            this.loading = true
            let data = {
                work_id: this.obra_selected,
                user_id: this.$route.params.id
            }
            Works.addUser(this.obra_selected, data).then((resp)   =>  {
                this.loadUserObras();
                this.success = true
                this.obra_selected = null
                this.loading = false
            })
        },
        async lookForVehicles(){
            if(this.fields.funcionario == null)
                return

            let pessoalID = this.fields.funcionario.Codigo

            this.$store.dispatch("listEquipamentosFromFuncionario", pessoalID)
                .then(res => {                    
                    this.fields.vehicles = res.data
                }) 
        },
        updateUser: function() {

            this.loading = true;

            let data = {
                id: this.$route.params.id,
                fields: this.fields
            }

            this.$store.dispatch("updateUser", data)
                .then(res => {

                    this.success = true;

                    let self = this;

                    setTimeout(function() {
                        self.$router.push("/super/utilizadores");
                    }, 2000)

                    this.loading = false;
                })
                .catch(err => {

                    if(err.response.status == 422){

                        this.$refs.form.$refs.form.setErrors(err.response.data.errors);

                    }

                    this.error = true;

                    this.error_message = err.response.data.message;

                    this.loading = false;
                });
        },
    },
    computed: {
        getVehiclesInfo(){
            return this.fields.vehicles
        },
        crumbs: function() {
        return [
            {
                text: 'Listagem de Utilizadores',
                disabled: false,
                to: '/super/utilizadores',
                exact: true,
            },
            {
                text: 'Atualizar utilizador',
                disabled: true,
                to: '#',
                exact: true,
            },
        ];
        }
    }
}
</script>

<style scoped>

</style>